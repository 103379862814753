@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #fff !important;
  overflow-x: hidden;
}
a {
  padding: 0!important;
  margin: 0!important;
  color: #000!important;
}
a:hover{
  text-decoration: none!important;
  color: #f6882d!important;
}

.calendarBox-airpassenger {
  padding: 15px;
  width: 92%;
  height: 60%;
  margin: 1% auto;
  border-radius: 10px;
}


.calendarBox {
  padding: 15px;
  width: 60%;
  height: 60%;
  margin: 1% auto;
  border-radius: 10px;
}
.fc .fc-daygrid-day-frame {
  position: relative;
  min-height: 103px;
  padding: 10px;
  font-weight: 500;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: aliceblue;
}
.daterate {
  width: 100%;
  font-size: 13px;
  color: #000;
}
.daterate label {
  margin: 0;
  width: 100%;
  display: flex;
  text-align: center;
  font-size: 14px;
  color: #4caf50;
  cursor: pointer;
}
.daterate span {
  margin: 0;
  width: 100%;
  display: block;
  text-align: left;
  font-size: 13px;
  color: #585454;
}
.fc-h-event {
  display: block;
  border: none;
  background-color: transparent;
}
table.fc-col-header tr {
  background: #ff6300;
}
a.fc-col-header-cell-cushion {
  font-size: 14px;
  font-weight: 500;
  color: #fff!important;
}
.fc .fc-toolbar-title {
  font-size: 20px;
  margin: 0;
  color: #ff6300;
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 8px;
}
.fc {
  width: 100%;
}
.fc .fc-button-primary {
  color: #fff;
  background: #ff6300;
}
table.fc-col-header tr th {
  border: 1px solid #ff6300;
}
.fc .fc-daygrid-day-top {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.tableico {
  margin-top: 5px;
  margin-bottom: 3px;
}

.tooltiprow {
  width: 100%;
}
.tooltipdtlsim{
  display: flex;
  align-items: flex-start;
}
.tooltipdtlsim label {
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  margin-top: 7px;
  padding-left: 10px;

}
.tooltipdtls label{
  width: 100%;
  font-size: 11px;
  font-weight: 500;
  margin: 0;
}
span.pricemini {
  font-size: 12px;
}
.fc-daygrid-dot-event:hover, .fc-daygrid-dot-event.fc-event-mirror {
  background: transparent;
}
.fc .fc-scrollgrid {
  border-collapse: separate;
  border-right-width: 0;
  border-bottom-width: 0;
}
.fc .fc-scroller-liquid-absolute {
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.fc .fc-scrollgrid-section-body table, .fc .fc-scrollgrid-section-footer table {
  border-bottom-style: hidden;
  background: #fff;
}
.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      -webkit-animation: App-logo-spin infinite 20s linear;
              animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #f2f2f2;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  .flightsearchpagebg {
    background: #f2f2f2;
    width: 100%;
    height: 100%;
  }
  @-webkit-keyframes App-logo-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes App-logo-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
body{
    font-family: 'Poppins', sans-serif !important;
}

#root{
    background: #f2f2f254;
}
.box-tlt {
    font-size: 15px;
    font-weight: 600;
    color: #ff6200;
    text-transform: uppercase;
    margin-bottom: 5px!important;
}

.listopt {
    font-size: 15px;
}

.optitems .MuiOutlinedInput-root {
    overflow: auto;
    display: flex;
    flex-wrap: nowrap !important;
}

.optitems .MuiInputBase-adornedStart{
    padding-right: 0px !important;
}
.optitems .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-popupIndicator{
    padding: 0px;
}

.optitems .MuiAutocomplete-endAdornment{
    position: relative !important;
    display: flex;
}
/* button.btn-class {
    font-size: 11px;
    font-weight: 500;
    color: #000;
    border: 1.2px solid #ccc;
    width: 100%;
} */
.flight-searschi-n-carousel {
    position: relative;
    min-height: calc(100vh - 90px - 200px);
    margin-bottom: 50px;
}
.btn-way {
    width: 110px;
    font-size: 13px!important;
    font-weight: 400!important;
    text-transform: capitalize!important;

}
.inputField {
    width: 100%;
    padding: 0!important;
    margin: 0!important;
    margin-top: 1px!important;
    margin-bottom: 5px!important;
    border-radius: 8px;
}
.inputField input {
    font-size: 13px!important;
}

.inputFieldLocation {
    width: 100%;
    padding: 0!important;
    margin: 0!important;
    margin-top: 3px!important;
    margin-bottom: 5px!important;
    border-radius: 8px;
}
 .inputFieldLocation input {
    font-size: 13px!important;
}
.inputFieldLocation input::-webkit-input-placeholder, textarea::-webkit-input-placeholder{
    color: #000!important;
    font-size: 13px !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
}
.inputFieldLocation input:-ms-input-placeholder, textarea:-ms-input-placeholder{
    color: #000!important;
    font-size: 13px !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
}
.inputFieldLocation input::placeholder ,textarea::placeholder{
    color: #000!important;
    font-size: 13px !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
}
.inputField input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color: #000!important;
    font-size: 13px !important;
    text-transform: capitalize !important;

    font-weight: 400 !important
}
.inputField input:-ms-input-placeholder, textarea:-ms-input-placeholder {
    color: #000!important;
    font-size: 13px !important;
    text-transform: capitalize !important;

    font-weight: 400 !important
}
.inputField input::placeholder,textarea::placeholder {
    color: #000!important;
    font-size: 13px !important;
    text-transform: capitalize !important;

    font-weight: 400 !important
}
.inputDate input::-webkit-input-placeholder {
    color: #000!important;
}
.inputDate input:-ms-input-placeholder {
    color: #000!important;
}
.inputDate input::placeholder {
    color: #000!important;
}

.lineBar {
    width: 100%;
    padding: 1px;
    margin: 15px 0;
    background-color: #ccc;
}

.btnGroup {
    width: 100%;
}
.inputDate {
    width: 100%;
    padding: 0!important;
    margin: 0!important;
    margin-top: 8px!important;
    margin-bottom: 5px!important;
}
.inputDate input {
    font-size: 13px!important;
}
.inputDate input::-webkit-input-placeholder {
    /* color: #1675b8!important; */
}
.inputDate input:-ms-input-placeholder {
    /* color: #1675b8!important; */
}
.inputDate input::placeholder {
    /* color: #1675b8!important; */
}
.addon-btn {
    font-size: 15px!important;
    font-weight: 400!important;
    width: 75%;
    margin-left: 25%!important;
}
.cntrowswp {
    position: relative;
}
.nonstopbtn {
    display: inline!important;
}
.nonstop-tlt {
    margin-left: 0px!important;
}

.nonstop-tlt span {
    font-size: 16px;
    color: #000;
    /* font-weight: 700; */
}
.nonstop-tlt .MuiCheckbox-root {
    padding: 9px;
}
.checkbox-tag {
    font-size: 15px;
    /* font-weight: 700; */
    color: #ff6200;
}
.WidthSet {
    max-width: 1000px;
    width: 100%;
}
div#nav-tabpanel-2, div#nav-tabpanel-1, div#nav-tabpanel-0 {
    background: #fff;
}
svg.icoarrow {
    font-size: 40px!important;
}
.tlt-btn-more {
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #000;
    font-weight: 600;
    cursor: pointer;
}
.tlt-mini-tag {
    margin: 0;
    font-size: 12px;
    color: #808080;
}
label.nonstop-tlt span {
    font-size: 12px!important;
    font-weight: 600;
}
.option-items {
    padding-left: 40px;
}

.ClassSelector {
    width: 100%;
    font-size: 17px!important;
    background: transparent;
    border: none;
    outline: none;
    font-weight: 600;
    padding: 6px 0px;
}
.items-list {
   border-bottom: .5px solid #ccc!important;
   font-size: 14px!important;
   padding: 5px!important;
}
.ClassSelector>div {
    width: 100%;
    font-size: 11px!important;
    background: transparent;
    border: none;
    outline: none;
    font-weight: 500px;
    padding: 0px 25px !important;
}
.divSet {
    position: relative;
}
.formRow {
    position: relative;
}
svg.times-icon {
    position: absolute;
    top: 36px;
    right: -26px;
    cursor: pointer;
}
.tabsPanelContainer {
    padding-right: 15px!important;
}
.tabsPanelbdr {
    box-shadow: 3px 2px 4px 1px #e9ecef;
    border-top: 2px solid #ff6200;
}
.text-red {
    color: #dc3545!important;
}

.tabsPanelbdr .box-tlt{
    padding: 0 10px !important;
}
.prstColl {
    position: relative;
    padding-right: 20px!important;
}
.prstColr {
    position: relative;
    padding-left: 20px!important;
}
svg.locationiconl {
    position: absolute!important;
    top: 40px;
    left: 13px
}
svg.locationiconr {
    position: absolute;
    top: 40px;
    left: 25px;
}
.gpgutter {
    margin-top: -10px;
}

.swipebox_multicity {
    position: absolute;
    top: 30px;
    left: 32%;
    z-index: 999;
    cursor: pointer;
}
.swipebox_modify {
    position: absolute;
    top: 30px;
    left: 49%;
    z-index: 999;
    cursor: pointer;
}
.swipebox_modify_multicity {
    position: absolute;
    top: 30px;
    left: 32%;
    z-index: 999;
    cursor: pointer;
}
.swipebox_modify_calenderfare{
    position: absolute;
    top: 30px;
    left: 49%;
    z-index: 999;
    cursor: pointer;
}


.BOOKING_CONFIRMED{
    padding: 3px 15px;
    background: #28a745;
    border-radius: 9px;
    font-weight: 500;
    display: block;
    width: -webkit-max-content;
    width: max-content;
    font-size: 10px;
    color: #fff;
}


.BOOKING_INCOMPLETE, .BOOKING_INCOMPLETE, .BOOKING_VOUCHERED , .BOOKING_PENDING, .BOOKING_FAILED, .BOOKING_INPROGRESS, .CANCELLATION_INPROGRESS  {
    padding: 3px 15px;
    background: #3c8dbc;
    border-radius: 9px;
    font-weight: 500;
    display: block;
    width: -webkit-max-content;
    width: max-content;
    font-size: 10px;
    color: #fff;
}


.BOOKING_CANCELLED{
    padding: 3px 15px;
    background: #dd4b39;
    border-radius: 9px;
    font-weight: 500;
    display: block;
    width: -webkit-max-content;
    width: max-content;
    font-size: 10px;
    color: #fff;
}

.BOOKING_HOLD{
    padding: 3px 15px;
    background: #00c0ef;
    border-radius: 9px;
    font-weight: 500;
    display: block;
    width: -webkit-max-content;
    width: max-content;
    font-size: 10px;
    color: #fff;
}


.search-wpr{
    display: flex;
    padding: 10px 15px !important;
    border-radius: 20px;
    background: #fef7f8;
}

.BOOKING_ERROR{
    padding: 3px 15px;
    background: #28a745;
    color: #fff;
    border-radius: 4px;
    font-weight: 400;
}
.p-font14{
    font-size: 12px;
}
.p-font14-rd{
    font-size: 12px;
}

.Slider-deales-xs {
    padding-top: 2%;
    padding-bottom: 2%;
}
/* MUI Button Override CSS */



.clear {
    color: rgba(0, 0, 0, 0.87) !important;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    background-color: #e0e0e0 !important; 
}
.MuiButton-sizeSmall{
    text-transform: uppercase;
    min-width: 50px;
    padding: 4px 14px !important;
   
}

button.Mui-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
    box-shadow: none !important;
    background-color: rgba(0, 0, 0, 0.12) !important;
}

.MuiButton-outlined{
    border-color:  #ccc !important;
}

.MuiButton-outlined:hover{
    border: 1px solid #ccc !important;
}

.MuiCardActionArea-root {
    display: block !important;
}

.btnGroup .MuiButtonBase-root{
    padding: 3px 9px !important;
    border-radius: 4px !important;
    border: 1px solid #ccc !important;
}

.MuiButtonGroup-groupedOutlinedPrimary:not(:last-of-type) {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-right-color: transparent !important;
}

.MuiButtonGroup-groupedOutlinedPrimary:not(:last-child) {
    border-right-color: transparent !important;
}

.MuiButtonGroup-groupedOutlinedPrimary:not(:first-of-type) {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0 !important;
    margin-left: -1px !important;
}

.btnGroup .MuiListItem-root{
    display: flex !important;
}

.sliderrange{
    height: 2px !important;
}

.MuiSlider-thumb{
    width: 12px !important;
    height: 12px !important;
    border-radius: 2px !important;
}
.items-list:hover {
    background-color: #ff6200!important;
    color: #fff;
}

.makeStyles-formControl-4 {
    margin-top: 16px !important;
    margin-bottom: 8px !important;
}

fieldset{
    border: none !important;
    border-width: none !important;
    border-color: none !important;
}
.flighttabsdetails-link {
    padding: 0px 15px !important;
    font-size: 10px !important;
    height: 22px !important;
    min-height: 30px !important;
}
.MuiOutlinedInput-root {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.MuiOutlinedInput-root:hover {
    border: 1px solid #ff6200 !important;
}

 .MuiAutocomplete-input {
    padding: 2.5px 38px 2.5px 1px !important;
    padding-left: 5px !important;
}

.MuiAutocomplete-endAdornment {
    right: 9px;
    top: auto !important;
}

.MuiSelect-select{
    padding: 6.4px 8px !important;
}
/* 
.MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    padding: 5px !important;
} */

.modifydata .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    padding: 0px !important;
}

.timingroup .MuiButton-sizeSmall {
    padding: 4px 9px !important;
}

.Mui-checked {
    /* color: #fc9e15 !important; */
}
.MuiPagination-ul{
    justify-content: center;
}
.MuiPagination-ul .Mui-disabled{
    background-color: #f2f2f2 !important;
}
.MuiPagination-ul .MuiPaginationItem-root{
    border-radius: 50% !important;
    transition: none !important;
}
.MuiPaginationItem-page:hover{
    border-radius: 50% !important;
    transition: none !important;
}
.MuiPagination-ul .Mui-selected {
    border-radius: 50% !important;
    transition: none !important;
}
.CircularProgress{
    justify-content: center;
}
.agecontrol select{
    padding: 23px 12px 5px !important;
}
input[type="file"] {
    padding: 5px 10px 14px!important;
}
.PrivateSwitchBase-root{
	background-color: transparent !important;
}
.MuiButtonGroup-grouped {
    border: 1px solid #ccc !important;
    border-radius: 4px !important;
}

table {
	display: table !important;
	visibility: visible !important;
}

.MuiMenu-list li{
    display: flex !important;
    padding: 6px 16px !important;
    font-size: 16px !important;
}

/* .MuiMenu-list li.Mui-selected{
    background-color: rgb(254 247 248) !important;
} */

.banner-bg-image{
    height: 550px;
}

.icon-time{
    color: #000;
}

.share-checkbox{
    padding: 0px 15px !important;
}


.gst_width{
    width: 750px !important;
}

.colspacemini {
    padding: 0!important;
}

.modifydata .MuiTabs-flexContainer {
    width: 50% !important;
    background: #fff;
}

.addon-btn {
    margin-top: 10px !important;
    background-color: #fff !important;
    color: #ff6200 !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    border-radius: 20px !important;
}

.r-table-container{
    background: transparent !important;
    box-shadow: none !important;
    overflow-x: visible !important;
    overflow-x: initial !important;
}



.MuiFormHelperText-root.Mui-error, .MuiFormHelperText-root, .text-danger{
    margin: 0 !important;
    font-size: 11px !important;
    color: #dc3545!important;
}
/* Responsive CSS Ends */

.react-multiple-carousel__arrow {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 21px;
    height: 20px;
    padding: 0;
    -webkit-transform: translateY(-55%);
    transform: translateY(-55%);
    cursor: pointer;
    color: #ff6200;
    z-index: 1 !important;
    border: none;
    outline: none;
    background: #ff6200 !important;
}

.MuiSnackbar-root {
    z-index: 99999 !important;
}

/* new UI for ITWgo */

.light {
    width: 10px;
    height: 10px;
    background: red;
    border-radius: 50%;
       bottom: 295px;
    position: absolute;
    z-index: 10;
    left: 20px;
    -webkit-animation: light 400ms ease-in-out 0s infinite alternate;
            animation: light 400ms ease-in-out 0s infinite alternate;
  }
  .cloud {
    position: absolute;
    top: 13px;
    right: -500px;
    opacity: .8;
    animation: cloud 30s linear 0s infinite reverse;
    z-index: 9;
  }

  .cloud img{
      width: 30px;
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
  }
  @-webkit-keyframes light {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }
  @keyframes light {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }
  @-webkit-keyframes cloud {
    from {right:0px;}
    to {right: 88%;}
  }
  @keyframes cloud {
    from {right:0px;}
    to {right: 88%;}
  }

  .progess-wrp{
      margin-top: 0%;
      background-color: #ddd;
  }
  .flight-progress-wrp .MuiLinearProgress-root {
        margin: 6px 0px !important;
        height: 2px !important;
        background-image: linear-gradient(90deg, transparent, transparent 50%, #ccc 50%, #ccc 100%), linear-gradient(90deg, #ddd, #ddd);
        background-size: 10px 2px, 100% 2px;
        border: none;

    }
   .flight-progress-wrp .MuiLinearProgress-bar{
       height: 2px !important;
       background-image: linear-gradient(90deg, transparent, transparent 50%, #fff 50%, #fff 100%), linear-gradient(90deg, #ddd, #ddd);
       background-size: 10px 2px, 100% 2px;
   }

    .dashed-line{
        display: block;
        border: 0.4px solid #ccc;
        background-color: #808080;
    }
    .share-cross-btn svg{
        font-size: 17px !important;
    }
    /* .reset-btn{
        text-align: right;
        border-bottom: 0.3px solid #444;
    } */

    .reset-btn {
        position: fixed;
        text-align: right;
        /* border-bottom: 0.3px solid #444; */
        background: #000;
        right: 25px;
        z-index: 99;
        margin-top: -15px;
        padding: 5px 0px;
    }

    .reset-btn button , .reset-btn button:hover{
        color: #fff;
        font-size: 12px;
        padding: 0px 0px 4px;
        font-weight: 600;
    }

    .groupfareG{
        width: 20px;
    }

    .coin-img {
        width: 25px;
    }


    .share-btn:hover .coin-img {
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
        -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
        -webkit-animation: rotate3d 2000 linear infinite;
                animation: rotate3d 2000 linear infinite;
        transition: all .8s;
    }

    .white-bg{
        background: #fff !important;
        border: white !important;
    }

    .text-black{
        color: #000 !important;
    }


    .btn-danger, .btn-danger:focus {
        color: #fff;
        background-color: #c82333 !important;
        border-color: #bd2130 !important;
    }

    .MuiButton-containedPrimary, .MuiButton-containedPrimary:hover{
        background: rgb(255, 99, 0);
        background-color: rgb(255, 99, 0) !important;
        padding: 5px 30px !important;
        border-radius: 8px 0px 8px 8px !important;
        font-size: 16px !important;
        font-weight: 600 !important;
        text-transform: capitalize !important;
        color: #fff !important;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 10px !important;
    }

    .MuiButton-containedSecondary, .MuiButton-containedSecondary:hover{
        background: #fff;
        background-color: #fff !important;
        padding: 5px 15px !important;
        border-radius: 8px 0px 8px 8px !important;
        font-size: 16px !important;
        font-weight: 600 !important;
        border: 1px solid #333333 !important;
        text-transform: capitalize !important;
        color: #333333 !important;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 10px !important;
    }

    .MuiButton-containedSuccess {
        background: #4caf50 !important;
        padding: 5px 30px !important;
        border-radius: 8px 0px 8px 8px !important;
        font-size: 16px !important;
        font-weight: 600 !important;
        text-transform: capitalize !important;
        color: #fff !important;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 10px !important;
    }
    .MuiButton-containedError{
        background: #c82333 !important;
        padding: 5px 30px !important;
        border-radius: 8px 0px 8px 8px !important;
        font-size: 16px !important;
        font-weight: 600 !important;
        text-transform: capitalize !important;
        color: #fff !important;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 10px !important;
    }

    .MuiButton-sizeSmall, .MuiButton-sizeSmall:hover{
        font-size: 12px !important;
        padding: 5px 15px !important;
    }
    .text-banner1{
        color: #ff6200;
        font-size: 20px;
        margin-top: 10px;
    }

    .text-banner2{
        font-size: 14px;
        margin: 0;
    }

    .sports-container{
        padding: 10px 5px 40px;
    }

    .sports-img img{
        width: 100%;
        height: 200px;
        object-fit: cover
    }

    .sports-img{
        position: relative;
        overflow: hidden;

    }
    .sports-title{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 200px;
        width: 100%;
        z-index: 99;
    }
    .sports-title h1{
        font-size: 25px;
    }
    .sports-img img{
        transition: all 0.5s ease-out;
        -webkit-backface-visibility: hidden;
    }
    .sports-img:hover img{
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
        transition: all 0.5s ease;
        -webkit-backface-visibility: hidden;
    }

    .card-img img{
        width: 100%;
        height: 200px;
    }

    .card-heading{
        background: #5c10da;
        margin: 0;
    }

    .card-heading h6{
        padding: 8px;
        margin: 0px;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        color: #fff;
    }

    .card-details {
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
        transition: 0.3s;
        width: 100%;
        padding: 15px 15px 5px;
        min-height: 140px;
        position: relative;
    }
      
    .card-details:hover {
        /* box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2); */
    }

    .card-details h1{
        font-size: 20px;
        margin: 0;
        margin-bottom: 5px;
    }

    .activity-list .card-details h1{
        font-size: 20px;
        margin: 0; 
        margin-bottom: 5px;
    }

    .card-img{
        position: relative;
    }

    .activity-list .card-img{
        padding-top: 20px;
        padding-right: 20px;
    }

    .activity-countries  .card-img img {
        width: 100%;
        height: 250px;
        object-fit: cover;
    }

    .activity-countries .card-details h1 {
        font-size: 22px;
        font-weight: 400;
        color: #444;
        margin-bottom: 0;
    }
    .activity-countries .card-details a {
        color: #5c10da !important;
        font-size: 13px;
    }
        
      .activity-countries .card-img{
        padding-right: 0px;

      }
      .card-details h2{
        font-size: 13px;
        font-weight: 400;
        margin-bottom: 15px;
      }

      .card-details a{
        color: #5c10da !important;
        font-size: 15px;
        display: inline-block;
        cursor: pointer;
        font-weight: 600;
      }

      .react-multi-carousel-item{
        margin-right: 10px;
      }

      .react-multi-carousel-list {
        height: 350px;
      }

    .twelve{
        position: relative;
        width: -webkit-max-content;
        width: max-content;

    }
    .three{
        width: 100%;
    }
    .three h1 {
        font-size: 30px;
        font-weight: 700;
        /* letter-spacing: 0.5px; */
        /* position: relative; */
        color: #000;
        margin-bottom: 0;
        margin-bottom: 0;
    }

    .horizontal{
        margin-top: 6%;
        padding-left: 25px;
    }
        
    .dealwrapper { max-width: 320px; background: #ffffff; border-radius: 8px; box-shadow: 0px 0px 50px rgba(0,0,0,0.15); position: relative;}
    .list-group { position: relative; display: block; background-color: #fff; border-radius: 5px;}
    .list-group h4 { font-size: 17px; margin-top: 6px;  margin-bottom: 10px;}
    .list-group p { font-size: 13px; line-height: 1.4; margin-bottom: 2px; font-style: italic;}
    .list-group-item { display: block; padding: 10px 20px 10px 15px !important; border: 1px solid rgba(221, 221, 221, 0.25); text-decoration: none;}
    .list-group-item .heading { color: #141f31;}
    .list-group-item .text { color: #272727;}
    .list-group-item.active .heading, .list-group-item.active .text { color: #ffffff;}

    .ribbon-wrapper { width: 88px; height: 88px; overflow: hidden; position: absolute; top: -3px; right: -3px; z-index: 1;}
    .ribbon-tag {font-size: 13px; text-align: center; -webkit-transform: rotate(45deg); -moz-transform: rotate(45deg); -ms-transform: rotate(45deg); -o-transform: rotate(45deg); position: relative; padding: 6px 0; left: -4px; top: 15px; width: 120px; color: #ffffff; box-shadow: 0px 0px 3px rgba(0,0,0,0.3); text-shadow: rgba(255,255,255,0.5) 0px 1px 0px; background: #343434; }

    .ribbon-tag:before, .ribbon-tag:after { content: ""; border-top: 3px solid #50504f; border-left: 3px solid transparent; border-right: 3px solid transparent; position:absolute; bottom: -3px;}
    .ribbon-tag:before { left: 0;}
    .ribbon-tag:after { right: 0;}

    .dealwrapper.purple .ribbon-tag { 
        background: rgb(255 98 0);
        background: linear-gradient(to bottom, rgb(255 98 0) 0%, rgb(255 98 0) 50%, rgb(255 98 0) 51%, rgb(255 98 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cb60b3', endColorstr='#db36a4', GradientType=0 );}


    .purple .list-group-item.active, .purple .list-group-item.active:focus, .purple .list-group-item.active:hover { background: linear-gradient(to bottom right, #00bfad, #99a3d4); border-color: #99a3d4;}
    /* .purple .list-group-item.active, .purple .list-group-item.active:focus, .purple .list-group-item.active:hover { background: linear-gradient(to bottom right, #ec407b, #ff7d94); border-color: #ff7d94;} */
    .red .list-group-item.active, .red .list-group-item.active:focus, .red .list-group-item.active:hover { background: rgba(248,80,50,1); border-color: #e23819;}
    .blue .list-group-item.active, .blue .list-group-item.active:focus, .blue .list-group-item.active:hover { background: rgba(73,73,250,1); border-color: #2525e0;}


    .sliderrange{
        color: #ff6200;
    }
    
    .breadcrumb-custom{
        padding: 8px 0px 4px;
    }

    .breadcrumb-custom nav{
        max-width: 1220px;
        font-size: 12.5px;
        margin: auto;
    }
    .breadcrumb-custom nav svg{
        font-size: 15px !important;
    }
    .breadcrumb-custom nav li a{
        color: #878ba1 !important;
        cursor: pointer;
    }
    .breadcrumb-custom nav li p{
        font-size: 12.5px;
    }
    .title-2{
        width: 100%;
    }
    .title-2 h1{
        font-size: 25px;
        font-weight: 600;
        margin-bottom: 15px;
    }

    .Mui-checked{
        color: #ff6200 !important;
    }

    textarea{
        width: 100%;
        padding: 10px;
        font-size: 15px !important;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
    

/* Responsive CSS Starts */

@media (max-width: 1370px){
    button.rc-menu-button.upadtedmenuitems {
        font-size: 15px;
    }
    .balanced-view {
        font-size: 12px !important;
    }
    .dropdown-profile {
        width: 150px;
    }
    button.profile {
        font-size: 11px !important;
    }

}

@media (max-width: 1300px){


    .slick-next {
        right: -20px !important;
    }

    .slick-prev {
        left: -30px !important;
    }

    .timing-item {
        font-size: 12px!important;
        padding: 10px !important;
    }
}

/* Responsive CSS Starts */
@media (min-width: 1500px) {
    .banner-text {
        padding: 4% 0px 0px !important;
    }
}

@media (max-width: 1370px){
    button.rc-menu-button.upadtedmenuitems {
        font-size: 15px;
    }

    .balanced-view {
        font-size: 12px !important;
    }

    .dropdown-profile {
        width: 150px;
    }
    button.profile {
        font-size: 11px !important;
    }

}

@media (max-width: 1300px){


    .slick-next {
        right: -20px !important;
    }

    .slick-prev {
        left: -30px !important;
    }

    .timing-item {
        font-size: 12px!important;
        padding: 10px !important;
    }
}
@media (min-width: 2000px) {
    
}

@media (max-width: 1199px){
   
    .viewinputhotel{
        font-size: 13px !important; 
    }
    .supplier-txt{
        font-size: 10px !important;
    }
    button.btnmarkup {
        margin-top: 10px;
    }
    .slick-track {
        -webkit-transform: translate3d(0, 0, 0) !important;
                transform: translate3d(0, 0, 0) !important;
    }
    .auth-wrapper {
        max-width: 1400px !important;
    }
    .box-prst {
        width: 100% !important;
        height: 142px !important;
    }
    .ico-box, .ico-box2 {
        width: 100% !important;
    }
    label.itemsradio span {
        font-size: 10px !important;
    }
    .share-cbx{
        width: auto !important;
    }

    .available-seats {
        font-size: 8px !important;
        display: block !important;
        width: 83px !important;
    }
    .timing-item {
        font-size: 12px!important;
        padding: 10px !important;
    }
    .timing-item {
        font-size: 11px!important;
        padding: 5px !important;
    }
    .share-cbx-round {
        margin-top: 22px !important;
    }
    .flight_details_btn {
        width: 100% !important;
    }
    .highlight-price {
        width: -webkit-max-content;
        width: max-content;
    }
    .icon-flight {
        width: 40px !important;
    }
}

@media (max-width: 1024px){
    .share-checkbox {
        padding: 2px !important;
    }
   
    .btn-booking-roundtrip {
        min-width: 90px;
        font-size: 13px !important;
        margin-left: 25px!important;
    }
    .white-bg-xs {
        background-color: #fff;
    }
    .btnview {
        font-size: 10px!important;
    }
    .flexset {
        padding: 0 0px !important;
    }
    .flightsearch-box {
        display: block !important;
    }
    svg.flighticonmodify {
        font-size: 18px !important;
    }
    .timing-item {
        font-size: 10px!important;
        padding: 6px !important;
    }
    button.btnBook {
        float: right;
        width: 40% !important;
    }
    .timing-item {
        font-size: 9px!important;
    }
    .swipebox_modify {
        top: 46% !important;
    }
    .supplier-txt{
        display: block;
    }
}


@media (min-width: 1300px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl , .MuiContainer-maxWidthLg{
        max-width: 1240px !important;
    } 
    .showFixedFilter {
        max-width: 265px !important;
    }
}

@media (max-width: 1250px){
    .showFixedFilter {
        max-width: 260px !important;
    }
}
@media (max-width: 1250px) and (min-width: 787px){
  
    .container, .container-lg, .container-md, .container-sm, .container-xl , .MuiContainer-maxWidthLg, .container-fluid{
        max-width: 100% !important;
    } 
    .btnSend{
        width: -webkit-max-content !important;
        width: max-content !important;
    }
    nav.NavbarHeader.navbar.navbar-expand-lg.navbar-light {
        padding: 0.8rem 0rem !important;
    }
    .special-offer-right .flightmininame {
        width: 75px !important;
    }
}

@media (max-width: 1150px) {
    .showFixedFilter {
        max-width: 240px !important;
    }
}

@media (max-width: 786px){
    .row {
        margin-right: 0px !important;
        margin-left: 0px !important;
    }
    .tlt-range {
        flex-basis: 100%;
    }
    .activity-list .card-details h1 {
        font-size: 16px;
    }
    .MuiMenu-list li {
        display: flex !important;
        padding: 5px 20px !important;
        font-size: 14px !important;
        min-height: 30px !important;
    }
    .card-details a {
        font-size: 14px;
    }
    .breadcrumb-custom {
        padding: 8px 15px 4px;
    }
    
    svg.MuiSvgIcon-root {
        font-size: 16px !important;
    }
    .white-bg-xs{
        padding: 0px !important;
    }
    .box-prst {
        width: 100% !important;
        height: auto !important;
    }
    button.tabTilte {
        font-size: 11px !important;
    }

    .arrowtabinfo img {
        left: 4px !important;
    }
    .closebox {
        top: -12px !important;
        right: -7px !important;
    }

    .setinputBox{
        margin-bottom: 10px;
    }

    .gstinputfield  {
        width: 100%;
    }

    .img-ico {
        height: 95px !important;
    }
    .tltprofilename {
        font-size: 20px !important; 
    }
    span.tltnamegray {
        font-size: 16px !important;
    }
    .paginationbox{
        width: 100% !important;
    }
    .tlt-content-ledger {
        margin-bottom: 0px !important;
        margin-top: 15px;
    }
    thead.dataheadoftable td {
        font-size: 11px !important;
    }
    .calendarBox-airpassenger {
        width: 100% !important;
    }
    .balanced-view{
        margin: 10px;
    }

    .viewbtndetails {
        margin-top: 15px !important;
    }
    .viewdropdown {
        left: 0px !important;
    }

    .header{
        padding:0px !important;
    }
    .scrollingdiv {
        height: 775px !important;
        overflow: hidden !important;
        padding-right: 0 !important;
    }
    
    .prstColr{
        padding-left: 8px!important;
    }

    svg.locationiconr {
        left: 12px;
    }
    div#nav-tabpanel-2 {
        width: auto;
    }

    .box-tlt {
        margin-bottom: 0px!important;
    }

    .swipebox {
        position: absolute;
        top: 46%;
        margin-top: -18px !important;
        z-index: 1;
        right: 0px;
    }

   .Subtlt-traveller {
        padding: 8px 0px !important;
    }
    
    .flightsearch-box {
        display: flex !important;
    }

    .MuiTab-textColorPrimary {
        font-size: 13px;
    }

    .modifysearchbox {
        width: 100% !important;
        margin-top: 21%; 
    }

    .modifybtnhotel{
        margin-bottom: 10px;
    }
    .supplier-txt{
        display: block;
        width: -webkit-max-content;
        width: max-content;
        margin: 15px;
    }
    .linklist-supplier {
        margin: 5% auto !important;

    }
    .MuiTextField-root {
        width: 100%;
    }

    .updateblncBtn button {
        font-size: 13px !important;
        padding: 4px 7px !important;
    }
    .requesttype {
        padding: 15px !important;
    }

    input[type="file"] {
        padding: 5px 3px 10px!important;
    }
    .tltleft {
        font-size: 16px !important;
        flex-basis: 34% !important;
    }
    .icondefine {
        flex-wrap: wrap;
    }
    .top-recharge{
        padding-left: 8px;
        margin-top: 10px;
    }
    .tlttxtset {
        flex-basis: 40% !important;
    }
    .txtsetinput {
        width: 100% !important;
    }
    .boxamountwidth {
        width: 100% !important;
    }

    .uploadBtn{
        margin-left: 9px;
    }

    label.rnditemsradio span:first-child {
        left: -47px !important;
    }
    .layover_rd {
        font-size: 10px !important;
        padding: 6px 6px !important;
        left: 35% !important;
     }
    span.roundtoname {
        font-size: 11px !important;
    }
    .roundflightairportaddress {
        padding-left: 15px !important;
    }

    .roundarrowtabinsidebox img {
        top: 5px !important;
        width: 36px !important;
        right: 22px !important;
    }
    .roundflightairportaddress p {
        margin-bottom: 5px !important;
    }
    .fc .fc-view-harness{
        height: 100vh !important;
        overflow: auto !important;
    }

    .fc .fc-scrollgrid-liquid {
        width: 1120px !important;
        overflow: scroll;
    }
    .calendarBox{
        width: 100% !important;
    }
    .fc .fc-daygrid-body {
        width: 100% !important;
    }
    .fc .fc-toolbar-title {
        font-size: 20px !important;
    }
    .alignleft{
        display: block !important;
        padding: 0px 15px !important;
    }
    .roundtltBaggage {
        font-size: 9px !important;
    }
    .btn-booking-roundtrip {
        min-width: 76px!important;
        font-size: 12px !important;
        padding: 2px !important;
    }
    .bookingflightroundtrip {
        bottom: 7% !important;
        z-index: 9999 !important;
        border-top: 1px solid #fff;
    }
    .bgcolor {
        padding: 15px 0px !important;
    }

    .swipeboxModify-res{
        top: 32% !important;
        left: 45% !important ;
    }

    .MuiTab-textColorPrimary {
        font-size: 11px !important;
    }
    .roundtripresult-mob .filter-msg-output {
        font-size: 12px !important;
        padding: 20px 0px 0px !important;
    }
    .filter-msg-output {
        font-size: 12px !important;
        padding: 10px 0px 0px !important;
    }
    .r-table-container {
        overflow-x: auto !important;
    }
    .filter-btn-float .btn-primary, .filter-btn-float .btn-primary:hover {
        color: #fff;
        background-color: #ff6200 !important;
        border-color: #ff6200 !important;
        font-size: 14px;
        font-weight: 400;
        padding: 12px;
        outline: none !important;
        box-shadow: none !important;
    }

    .filter-btn-float  {
        position: fixed;
        background: #ff6200;
        width: 100%;
        bottom: 0;
        left: 0;
        text-align: center;
        z-index: 99;
        border: none;
        border-radius:0px ;
    }
    .filter-mob ,.sortby-mob {
        position: fixed !important;
        height: 100vh;
        width: 87% !important;
        overflow: auto;
        z-index: 99;
        display: none;
        background: #fff;
        padding: 5% 10px 50% 10px !important;
    }
    .d-block-xs{
        display: block !important;
    }
    .btn-primary {
        color: #fff;
        background-color: #fc9f14;
        border-color: #fc9f14;
    }
    .flighttltname {
        font-size: 10px !important;
    }
    .flightsubtltname {
        font-size: 8px !important;
    }
    .flightsearchmodify-btn button {
        font-size: 11px !important;
        padding: 4px 2px !important;
        font-weight: 600 !important;
        border: none !important;
    }
    .flightsearchmodify-btn svg{
        font-size: 12px !important;
    }
    .modify-col{
        border-right:0px !important ;
    }
    .flex-servicesbox {
        width: 107% !important;
    }
    .flighttabsdetails-link {
        padding: 0px 5px !important;
        font-size: 13px !important;
    }
    .tltBaggage-desc {
        font-size: 12px !important;
    }
    .layover {
        font-size: 10px !important;
        top: -11px !important;
        left: 32% !important;
    }
    span.icoarrowfromto img {
        width: 18px !important;
        opacity: 1 !important;
    }

    .flightsearchmodify-btn {
        text-align: center;
    }
    .flight-details-mob {
        display: none !important;
    }
    .bookingflightroundtrip{
        padding: 8px 0px !important;
    }
    .flight_details_btn{
        width: 100% !important;
        margin-top: 10px;
    }
    .flight_details_oneway::after, .flight_details_round::after{
        background-color: transparent !important;
    }
 
   
    .modify-section {
        justify-content: flex-start !important;
    }
    .footerbookingflightnumber {
        font-size: 10px !important;
    }
    h3.tlt-lable {
        font-size: 18px !important;
    }
    .tlt-depart {
        font-size: 17px !important;
    }
    .date-depart {
        font-size: 10px !important;
        margin-top: 0px !important;
    }
    .abstlt {
        font-size: 17px !important;
    }
    .MuiDialog-paperScrollPaper {
        margin: 5px !important;
        width: 100% !important;
    }
    .btnmarkup{
        margin-top: 10px !important;
    }
    .updateTlt_hotel {
        font-size: 11px !important;
    }

    .viewinputhotel {
        font-size: 10px !important;
    }
    button.updateBtnHotel {
        font-size: 9px !important;
        padding: 4px 0px !important;
    } 
    .offernpricedtls {
        margin-top: 0px !important;
    }
    .ratedetailcard {
        width: 100% !important;
        padding: 10px !important;
        margin-left: 0px !important;
    }
    .roomrent {
        padding: 10px 0px !important;
        font-size: 15px;
        text-align: left !important;
    }
    .cancellationboxhotel {
        padding: 0px 0px 10px !important;
    }
    .roombox {
        padding: 15px 0px !important;
    }
    .btnbookhotel {
        padding: 15px 0px !important;
    }
    .fc-theme-standard td, .fc-theme-standard th{
        width: 130px !important;
    }
    .sideimgboxleft {
        padding-right: 0px !important;
    }
    .slideimgbox {
        width: 100% !important;
        margin-top: 55px !important;
    }
    .colspacemini {
        padding: 0px 15px !important;
    }
    .galleryslider .slick-slide {
        height: auto !important;
    }
    .galleyheader{
        width: 100% !important;
    }
    .tabcontentsbox .roundmini-tag {
        margin-left: 0px !important;
        margin-bottom: 10px;
    }
    .roundtripresult-des{
        display: none;
    }
    label.rnditemsradio span {
        margin-left: 30px !important;
    }

    .roundtripdetails-mini .roundflightimg {
        display: block !important;
        align-items: flex-end;
    }
    .roundflightdetailscard {
        /* padding-left: 12px !important; */
        padding: 10px 0px 0px !important;
    }
    .flightminitime {
        font-size: 13px !important;
    }
    .arrow-img-round img {
        top: 17px !important;
        width: 13px !important;
        left: 56% !important;
    }

    ul.roundlisticon {
        display: none !important;
    }
    .roundflightimg {
        display: block !important;
    }
    .roundarrow-img img {
        left: 4px !important;
        width: 20px !important;
    }
    .round-radio-fieldwith-iconlist {
        padding-left: 0px !important;
    }
    label.rnditemsradio span {
        margin-left: 21px !important;
        margin-top: -1px;
    }
    .tltrndheader {
        color: #ff6200;
        font-size: 14px;
    }

    .roundtripdetails-mini label.rnditemsradio span{
       display: none;
    }
    .roundtripdetails-mini ul.roundservicesBox {
        margin: 5px 10px;
    }
    .roundtltBaggage-desc {
        font-size: 10px !important;
    }

    .define-timecalc {
        font-size: 8px !important;
        padding-top: 4px !important;
    }
    .roundtripdetails-mini .flagflt {

    }
    .roundflightdetailscard-xs {
        padding: 10px 7px 5px;
        background: #fff;
        border-radius: 0px 10px 10px 0px;
        margin-bottom: 15px;
        box-shadow: -8px 3px 5px 1px rgb(0 0 0 / 15%);
    }
    .define-stop {
        font-size: 8px !important;
    }
    button.mailbtn, button.selectbtnOutline {
        padding: 2px 5px !important;
    }
    .round-highlight-price {
        font-size: 14px !important;
    }
    .view-more-btn{
        padding: 0px !important;
    }
    .flighttimexs{
        font-size: 11px !important;
    }
    .flexset-rnd-inside {
        padding: 0px 10px !important;
    }
    .flightmininame {
        font-size: 9px !important;
    }
    .flightminidata {
        font-size: 8px !important;
    }
    .rndflightname {
        font-size: 10px !important;
        width: -webkit-max-content;
        width: max-content;
    }
    .rndflightnumber {
        font-size: 11px !important;
        width: -webkit-max-content;
        width: max-content;
    }
    .share-cbx-round {
        margin-top: 6px !important;
    }
    .roundtripdetails-mini .roundmini-tag {
        margin: 5px 0px !important
    }
    .tltrndheader{
        white-space: normal !important;
        white-space: initial !important;
    }
    .roundflightdetailscard {
        margin-bottom: 10px !important;
    }
    .scroll-roundtripresult{
        height: 100vh !important;
        overflow: scroll !important;
    }
    .share-container {
        bottom: 44px !important;
        width: 250px !important;
        padding: 10px 15px !important;
    }
    .share-ttl-1 {
        margin-bottom: 0px important;
        font-size: 11px !important;
    }
    .share-outline-input {
        margin-top: 0px !important;
    }
    .share-row-wrapper {
        margin-top: -8px !important;
    }
    .arrowimg-mob{
        top: 5px !important;
    }
    
    .flightairportaddress {
        padding: 0px 15px !important;
    }
    .viewresultbooking {
        padding: 5px 0px !important;
    }
    .highlight-price {
        font-size: 16px !important;
    } 
    .flightnumber {
        width: -webkit-max-content;
        width: max-content;
        font-size: 10px !important;
    }
    .departflightminitime {
        font-size: 15px !important;
    }
    .departflightname {
        font-size: 13px !important;
    }
    .departflightnumber {
        font-size: 9px !important;
    }
    .departimgminifly {
        margin-right: 12px !important;
    }
    .departdefine-stop , .departdefine-timecalc {
        font-size: 10px;
    }
    .baggagedepart {
        padding: 15px 0px !important;
    }
    .departcode {
        font-size: 18px !important;
    }
    .baggageBox {
        margin-left: -10px !important;
    }
    .items-head {
        padding: 0px 15px !important;
    }
    span.boldtxt {
        font-size: 14px !important;
    }
    .definedepartpwidth {
        margin-top: 10px;
    }
    .table-mob{
        min-width: 100% !important;
    }
    .share-text-input input{
        margin-top: 0px !important;
    }
    .choosebtn {
        margin-left: 0px !important;
    }
    .para-txt{
        padding: 10px !important;
    }
    .insideRow {
        padding: 0 0% !important;
    }
    .itemRow {
        padding: 20px 10px !important;
    }
    button.btnsubmitchange {
        min-width: 100px !important;
    }
    .tltprofilename {
        margin-left: 0% !important;
    }
    .balance_show {
        z-index: 9;
    }
    .tbleBox {
        display: block !important;
    }
    .tblecelltlt {
        width: 100% !important;
        text-align: left !important;
    }

    .titlepricetag {
        flex-basis: 30% !important;
    }
    label.itemsradio span {
        font-size: 11px !important;
    }
    li.listitemsicon img {
        margin: 5px 2px  !important;
    }
    .share-cbx {
        margin-top: 10px;
        width: -webkit-max-content !important;
        width: max-content !important;
    }
    .setbtnprevnext {
        width: 100% !important;
        justify-content: start !important;
    }
    .more-flights-btn, .view-more-btn {
        font-size: 11px !important;
        font-weight: 600 !important;
    }
    .roundmini-tag {
        margin-left: 1px !important;
    }
    .flighttabsdetails .MuiTab-textColorPrimary, .flighttabsdetails .MuiTab-textColorPrimary.Mui-selected {
        min-width: 58px !important;
        width: 58px !important;
        padding: 0px 5px !important;
        font-size: 11px !important;
        min-height: 50px
    }
    .moreOption{
        padding: 10px 0px;
    }
    .flightsearchmodify-btn {
        text-align: right !important;
        padding: 5px !important;
    }
    .onewaycontainer .flightmininame {
        font-size: 9px !important;
    }
    .onewaycontainer .share-cbx {
        margin-top: 10px !important;
        margin-left: 35% !important;
        top: -10% !important;
    }
    .filter-btn-float svg.MuiSvgIcon-root {
        margin-top: -2px;
    }
    .sortby-des{
        display: none !important;
    }

    .sortby-label {
        font-size: 14px  !important;
        color: #000  !important;
    }
    .sortby-mob .MuiFormControlLabel-label{
        font-size: 14px;
        font-weight: 500;
    }
    .sortby-mob .MuiRadio-colorPrimary {
        padding: 0px 10px !important;
    }
    .sortby-mob .viewresultbooking.addonbackground.row {
        background: #fff !important;
    }
    .sortby-radiogrp{
        align-items: end;
    }
    .sortby-radiogrp label{
        margin: 2px 0px !important;
    }
    .sort-by-border{ 
        border-bottom: 1px solid #ccc;
        padding: 20px 0px;
    }
    .roundtltfaredetails {
        font-size: 11px !important;
    }
    .roundtlt-total-right{
        text-align: right;
    }
    /* .MuiNativeSelect-select {
        padding: 0px 0 5px !important;
    } */
    .roundtabsBox {
        margin: 2px 10px !important;
    }
    .onewaycontainer .flighttabsdetails .MuiTab-textColorPrimary, .onewaycontainer .flighttabsdetails .MuiTab-textColorPrimary.Mui-selected {
        min-width: 80px !important;
        width: 85px !important;
        text-align: center;
        font-size: 9px !important;
        padding: 10px !important;
    }
    .onewaycontainer .arrowtabinfo img {
        left: 13px !important;
        width: 30px;
    }
    .onewaycontainer .flightairportaddress {
        padding: 0px 0px !important;
    }
    .onewaycontainer .arrowtabtxt {
        font-size: 9px !important;
    }
    .onewaycontainer .tltdate {
        margin-top: 0px !important;
    }
    .onewaycontainer .define-timeinfo{
        padding-top: 12px !important;
    }
    .more-flights-btn {
        padding: 0px !important;
    }
    .tlt-total-left{
        text-align: left !important;
    }
   
    .definewidth {
        margin: 0px !important;
    }
    .Subtlt-traveller {
        font-size: 10px !important;
        padding: 2px 0px !important;
    }
    .px-xs-0{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .pl-xs-0{
        padding-left: 0px !important;
    }
    .pr-xs-0{
        padding-right: 0px !important;
    }
    .pt-xs-3{
        padding-top: 15px !important;
    }
    .pb-xs-3{
        padding-top: 15px !important;
    }
    .sideimgboxright {
        display: flex !important;
    }
    .arrowtabtxt {
        font-size: 10px !important; 
    }
    .arrowbox-multi{
        width: 50px !important;
    }
    .multi-flighdatetime{
        text-align: center;
    }
    button.seatbtnpopup {
        font-size: 10px !important;
        min-width: 110px !important;
    }
    .inputBoxgst{
        margin-top: 10px;
    }
    .tlt {
        font-size: 15px !important;
    }
    .faredetailprice {
        font-size: 16px !important;
    }
    .dailogBox-tlt {
        display: block !important;
    }
    .dropdown-profile {
        width: 100% !important;
        margin-left: 0px !important;
    }

    .login-right-mob{
        display: none !important;
    }
    .logo-account {
        margin-left: 0px !important;
    }
    .header-logo {
        width: 65% !important;
        padding-top: 15px !important;
    }
    .navbar {
        padding: 1.5rem 1rem !important;
    }
    .viewroundresultbooking{
        padding: 3px 0px !important;
    }
    .viewroundresultbooking .MuiNativeSelect-select {
        font-size: 13px !important;
        padding: 0px!important;
    }
    .faretype-p {
        font-size: 13px;
    }
    .swipebox_modify{
        z-index: 9 !important;
    }
    .tabsBox .flightimg {
        display: block !important;
    }
    .roundviewbtndetails {
        margin-top: 4px !important;
    }
    .btnview {
        font-size: 10px!important;
        width: -webkit-max-content !important;
        width: max-content !important;
        padding: 5px 7px!important;
    }
    .arrowmultiImg img{
        left: 5px !important;
    }
    .multiarrowimg{
        top: 5px !important;
    }
    button.btnEditProfile {
        font-size: 12px !important;
    }

    .hoteldsgn {
        max-width: 100% !important;
    }
    button.btntrnlog{
        min-width: 100px !important;
    }
    .share-container, .share-container-round {
        bottom: 44px !important;
        width: 250px !important;
        padding: 10px 15px !important;
    }
    .imgdepartbooking {
        display: block !important;
    }
    .countrycode{
        width: auto !important;
    }
    .sports-container {
        padding: 10px 5px 20px;
    }
    .subtlt-fly {
        font-size: 11px !important;
    }
    .tlt-fly {
        font-size: 16px !important;
    }
    .MuiMenu-list li {
        width: 100% !important;
    }
    .offline-total-ttl{
        padding: 0px 8px;
        font-size: 17px;
    }
    .Fdetails {
        font-size: 12px !important;
    }
    .Udetails {
        font-size: 13px !important;
    }
    .selecthoteltabs .MuiTab-root {
        min-width: 120px !important;
    }
    .MuiTabs-scroller {
        overflow: auto !important;
    }
    .share-txtbtn {
        font-size: 11px !important;
    }
    .searchtab-main {
        width: 100% !important;
        position: relative !important;
    }
    .search-box .MuiTabs-flexContainer {
       display: -webkit-box !important;
    }
    .div-border-right {
        display: none;
    }
    .search-wpr .box-input-calender {
        width: 100% !important;
    }
    .search-wpr .box-input-passclass {
        width: 100% !important;
    }
    .box-tlt {
        font-size: 10px !important;
    }
    .MuiButton-root {
        float: revert;
    }
    .search-wpr {
        display: flex;
        padding: 10px !important;
        border-radius: 20px;
        background: #fef7f8;
    }
    .search-wpr .mob-input-border{
        /* border: 1px solid #ccc; */
        border-radius: 10px ;
        margin-bottom: 10px;
        display: block !important;
    }
    .search-wpr .mob-input-border-r{
        /* border: 1px solid #ccc; */
        border-radius: 10px ;
        margin-bottom: 10px;
        display: block !important;
    }
    .modifydata .MuiTab-root {
        font-size: 13px !important;
        padding: 0px !important;
    }
    .search-box .MuiTabs-scroller {
        /* overflow: hidden !important; */
    }
    .search-wpr .box-input-w {
        padding-top: 10px !important;
    }
    .search-wpr .inputFieldLocation{
        margin-bottom: 2px!important;
    }
    .searchtab-main .MuiTab-textColorPrimary{
        display: revert !important;
        padding: 0px 20px !important;
    }

    .modifydata .MuiTabs-flexContainer{
        width: 100% !important;
    }
    .m-inherit {
        margin: unset !important;
    }
    .footerimg img {
        width: 50% !important;
    }
    .listlinkitemsbox {
        display: block !important;
        margin-top: 0px !important;
    }
    .footeraddress p {
        margin-bottom: 10px !important;
        margin-top: 0px !important;
    }
    .linklistul ul {
        font-weight: 500;
        padding: 0px 10px !important;
    }
    .banner-bg-image {
        background-size: cover !important;
    }
    .horizontal {
        margin-top: 5%;
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .footer {
        padding: 20px 0px 20px !important;
    }
    .banner-des-1, .banner-des-2, .banner-des-3, .banner-des-4, .banner-des-5{
        width: 100% !important;
        font-size: 13px !important;
    }
    .banner-1, .banner-2, .banner-3, .banner-4, .banner-5{
        padding-top: 50px !important;
    }
    .navbar {
        padding: 0.8rem 1rem !important;
    }
    .imgfnd img {
        width: 100%;
    }
    .tltrndheader-flight {
        background: #0a74b9;
        padding: 2px 10px;
        color: #fff;
        font-size: 12px;
        margin-bottom: 10px;
        margin-top: 5px;
    }
    .itemssecttag {
        font-size: 8px !important;
    }
    .addon-btn {
        width: 100%;
        margin-left: 0%!important;
    }
    .filter-msg-output-multi{
        padding: 5px 0px !important;
    }
    .setbtnprevnext-multi{
        padding: 0px 10px !important;
    }
    .shownetmulticities {
        margin-top: 3px !important;
        font-size: 14px !important;
    }
    .flexset-intr{
        padding: 0px 30px !important;
    }
    .modify-section-des{
        display: none;
    }
    .modifyheadercontent{
        width: 100%;
        padding: 12px 0px !important;
        position: fixed;
        z-index: 99;
        top: 0px;
    }
    .departcodedetails span {
        font-size: 11px !important; 
    }    
    .travellerdetailsBox {
        padding: 15px !important;
    }
    .sectorToggleBtn {
        font-size: 12px !important;
        padding: 5px 2px !important;
    }
    .sectorToggleBtnActive{
        font-size: 12px !important;
        padding: 5px 2px !important;
    }
    .tlt-traveller{
        display: flex;
    }
    .texticon svg {
        font-size: 43px !important;
    }
    .txt_btn{
        text-align: center !important;
        margin-top: 30px !important;
        justify-content: center;
        display: flex;
    }
    .txt_btn button{
        font-size: 10px !important;
    }

    .tdledger p{
        width: -webkit-max-content;
        width: max-content;
    }

    .voucher-container {
        zoom: 0.35;
    }

    .button.selectbtnOutline{
        margin-top: 10px;
    }
    .sectorToggleBtnActive{
        padding: 5px 15px !important;
    }
    .sectorToggleBtn{
        padding: 5px 15px !important;
    }

    .report-more-btngrp {
        text-align: left !important;
    }

    .baggage-heading li {
        font-size: 12px !important;
    }
    .meal-img img {
        width: 100% !important;
    }
    .meal-price {
        font-size: 13px !important;
    }
    .baggage-img img {
        width: 100% !important;
        padding: 3px !important;
    }
    .tlthtlstr {
        font-size: 18px !important;
    }
    .hoteladdress p {
        font-size: 12px !important;
    }
    .ratedetailcard {
        padding: 20px !important;
    }
    .galleryslider{
        margin-top: 18% !important;
    }
    .tabsPanelContainer{
        padding: 0px !important
    }
    .account-ledger{
        margin: 15px 0px !important;
    }
    .tlt-content-ledger , .table-box-found {
        padding: 0px 15px !important;
    }
    .tlt-ledger {
        padding: 15px 0px !important;
    }
    .searchtab-sp{
        width: 40% !important;
    }
    .offer-bg-img {
        height: 300px !important;
    }
    .special-offer-right .flightmininame {
        font-size: 13px !important;
        text-align: center;
    }
    .special-offer-right .arrowbox {
        width: 14% !important;
    }
    .specailfarelabel {
        padding: 10px 0px;
        font-size: 20px !important;
    }
    .boxdivition-row {
        padding: 15px 0px !important;
    }
    .moreBtnList {
        left: 18% !important;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }
    .balanceBox {
        margin: 0px !important;
    }
    .flight-invoice-container{
        zoom: 0.4;
    }
    .tltfaretype-desc {
        position: relative !important;
        padding: 0px 15px 0px !important;
        right: 0px !important;
        top: 10px !important;
    }
    .search-box-hotel{
        margin-top: -70% !important;
    }
    .special-offer-button{
        height: 300px !important;
    }
    .top-up-footer-spinner .divLoader {
        margin-bottom: 17% !important;
    }
    .top-up-footer-spinner .svgLoader {
        width: 100px !important;
    }
    .banner-text{
       display: none;
    }

    .banner-des-1, .banner-des-2, .banner-des-3, .banner-des-4, .banner-des-5,  .banner-des-6, .banner-des-7, .banner-des-8, .banner-des-9{
        width: 100% !important;
        font-size: 15px !important;
    }
    .btnSend {
        padding: 6px 25px !important;
    }
    .rightBoxContainer {
        padding: 15px 0px 0px !important;
    }
    
    .itemsclockicon svg.MuiSvgIcon-root {
        font-size: 25px !important;
        margin-right: 5px ;
    }
    .header-topup {
        margin-bottom: 15px;
    }
}


@media (max-width: 550px){
    .footeraddress1 p{
        word-break: break-word;
    }
    .flightsearch-box {
        display: flex !important;
        padding: 0px 5px !important;
    }
    
    .icoBox {
        width: 86px !important;
        font-size: 14px !important;
    }
    img.icostatic {
        width: 41px !important;
        height: 41px !important;
    }

    .tlt-services {
        width: 100% !important;
        margin-left:  25px !important;
        padding-right: 15px;
    }
    .tlt-services ul {
        display: flex;
    flex-wrap: wrap;
    }

    .box-prst {
        height: auto !important;
    }

    h2.tlt-link {
        margin-right: 0px !important;
        font-size: 19px !important;
        margin-top: -12px ;
    }
    .loginBox {
        margin: 20px 0px !important;
    }
    .box-prst {
        padding: 10px 30px !important;
    }
    .tlt-services ul li {
        margin: 0 5px;
        width: 100%;
        padding-right: 20px;
    }
    .footeraddress {
        padding: 5px 10px;
    }

    .socialicons {
        margin-bottom: 10px;
    }

    .registerBox {
        margin: 15px 0px !important;
    }

    span.linkcreateaccount {
        display: block;
    }

    .gst_width {
        width: 100% !important;
        padding: 0px 35px !important;
    }

    .gst_width .tlt-form {
        flex-basis: 60% !important;
    }
    .managegstrow_new.row {
        padding: 0px !important;
    }

    .updateblncBtn button {
        margin-right: 10px !important;
    }

    .balncContainer .inputrow{
        padding: 0px 15px !important;
    }

    .Slider-deales-xs{
        margin-top: 5% !important;
    }
    .resulthoteltlt{
        margin: 0px !important;
        padding: 15px 0px !important;
        margin-bottom: 15px !important;
    }
    .tlt-hotel {
        margin-top: 10px;
        margin-bottom: 5px !important;
    }
    .addresshotel p {
        margin-bottom: 5px !important;
    }
    .facilites span {
        font-size: 11px !important;
    }
    
    .hotelrow img{
        object-fit: cover;
    }
    button.hoteltab {
        padding: 0 15px !important;
    }

    .hoteltabs .MuiTabs-scroller {
        overflow: auto !important;
    }
    .tltbooknow button {
        font-size: 10px !important;
    }
    .flexdiv{
        margin-top: 7% !important;
    }
    .sideimgboxright button{
        font-size: 12px !important;
        min-width: 50px !important;
    }
    .overlayposotion {
        top: 4px !important;
        left: 31% !important;
        font-size: 10px !important;
    }
    .define-timecalc, .define-stop{
        text-align: center;
    }
      .three h1 {
        font-size: 17px;
    }
    .heading-right {
        flex-basis: 60%;
    }

    .activity-list .card-img {
        padding-top: 20px;
        padding-right: 0px;
    }
    .sports-img img, .sports-title {
        height: 100px;
    }
    .sports-title h1 {
        font-size: 18px;
    }

    .px-xs-3{
        padding: 0 15px;
    }
}

@media (max-width: 375px){

    .btn-collapse button {
        padding: 6px 7px!important;
        font-size: 12px!important;
    }
}

@media (max-width: 330px){
    .flightsearch-box {
        display: block !important;
        padding: 0px 5px !important;
    }

    .footer .container-fluid{
        padding: 0px !important;
    }
    .updateTlt_hotel {
        font-size: 10px !important;
    }

    .setbtnprevnext {
        width: 100% !important;
        padding: 0px 20px 10px !important;
    }

    .icoBox {
        width: 70px !important;
        font-size: 14px !important;
    }

    .search-box .MuiGrid-root {
        display: block !important;
    }
    button.tabTilte {
        min-width: 68px !important;
    }

    .Slider-deales-xs {
        margin-top: 76% !important;
    }
    .flightminitime {
        font-size: 8px !important;
    }
    .round-highlight-price {
        font-size: 8px !important;
        margin-bottom: 3px;
    }
    .share-cbx-round {
        margin-left: -5px !important;
    }
    .flightimg {
        display: block !important;
    }
    
    
}

@media (min-width: 768px) and (max-width: 1000px) {
  
    
    .supplier-txt {
        width: auto !important;
    }
    .prstColr {
        padding-left: 10px!important;
    }
    svg.locationiconr {
        left: 14px !important;
    }
    .addon-btn {
        width: 100% !important;
        margin-left: 0px !important;
        margin-top: 10px !important;
    }
    .swipebox {
        top: 74px !important;
    }
    .share-cbx {
        width: -webkit-max-content !important;
        width: max-content !important;
    }
  
}

@media (min-width: 787px) {
    .modify-section-xs{
        display: none;
    }
    
    .filter-btn-float{
        display: none;
    }

    .filter-mob{
        display: block !important;
    }

    .btnview {
        width: 100% !important;
        font-size: 10px !important;
    }
    .roundtripresult-mob{
        display: none;
    }
    .sortby-mob{
        display: none;
    }
  
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}
 .footer{
    background: #fff;
 }
 
 .logo-padding {
    padding: 14px;
  }
  
  .mb-logo {
    display: none;
  }
  
  .nav-links {
  
    display: flex;
    align-self: center;
    align-items: center;
  }
  
  * {
    padding: 0;
    margin: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
  }
  
  body {
    font-family: Poppins;
  }
  
  footer nav {
  
    display: flex;
    justify-content: space-around;
    background: #ffff;
    height: 80px;
    -webkit-filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.16));
            filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.16));
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-items: center;
    z-index: 99999;
  
  }
  
  label.logo {
    color: white;
    font-size: 35px;
    line-height: 80px;
    padding: 0 100px;
    font-weight: bold;
  }
  
  nav ul {
    float: right;
  }
  
  nav ul li {
  
    margin: 0 5px;
  }
  
  nav ul li a {
  
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
    padding: 7px 10px;
  
  }
  
  .icon-position {
    margin-top: 25px;
  }
  
  .checkbtn {
  
    display: none;
  }
  
  #check {
    display: none;
  }
  
  .desk-logo {
    display: block;
  }
  .footer-bottom{
    background-color: #000;
    padding: 5px;
  }
  .footer-bottom p{
    font-size: 15px;
  }
  .itwuxp-logo {
    width: 100px;
  }
  
  @media (max-width: 952px) {
    label.logo {
      font-size: 30px;
      padding-left: 50px;
    }
  
    nav ul li a {
      font-size: 16px;
    }
  
    nav {
      background: #ffff;
      height: 80px;
      width: 100%;
    }
  }
  
  @media (max-width: 858px) {
    .desk-logo {
      display: none;
  
    }
  
    .mb-logo {
  
      display: block;
    }
  
    nav {
      background: #ffff;
      height: 56px;
      width: 100%;
      justify-content: flex-start;
      padding-left: 16px;
  
    }
  
    .checkbtn {
      display: block;
    }
  
    ul {
      position: fixed;
      width: 100%;
      height: 100vh;
      background: #ffffff;
      top: 56px;
      left: -100%;
      text-align: center;
      transition: all .5s;
      display: block !important;
    }
  
    nav ul li {
      display: block;
      margin: 22px 0;
      line-height: 30px;
    }
  
    nav ul li a {
      font-size: 20px;
    }
  
    #check:checked~ul {
      left: 0;
      display: block;
    }
  }
  
  
  
  footer#site-footer {
    margin: 0 auto;
    width: 1200px;
    background: #ffff;
    padding: 1rem;
    color: #a8a8a8;
    height: 260px;
  }
  
  /*footer#site-footer a {
      color: #a8a8a8;
  }*/
  
  .normal-links-styling {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 36px;
    color: #666666;
    text-decoration: none;
  }
  
  .direction-styling {
    color: #5C0FD9;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
  }
  
  
  .address-styling {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #666666;
    width: 77%;
  }
  
  footer#site-footer h1 {
    color: #333333;
        align-self: center;
  }
  
  .copyRight-stying {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #666666 !important;
    text-align: center;
    margin-bottom: 0;
  }
  
  .tandc-styling {
    margin-bottom: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #5C0FD9 !important;
    padding: 0px 12px;
  
  }
  .tandc-styling a{
    color: #5C0FD9 !important;
  
  }
  
  
  .footerTermscondition-layout {
    display: flex;
    justify-content: flex-end;
  }
  
  footer#site-footer section.horizontal-footer-section {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    /*  padding: 2.38rem 2rem;*/
  }
  
  footer#site-footer section.horizontal-footer-section#footer-top-section {
    justify-content: center;
  }
  
  footer#site-footer #footer-logo {
    margin-right: auto;
    flex-shrink: 0;
  }
  
  footer#site-footer #footer-logo img {
    height: 2.28rem;
  }
  
  footer#site-footer ul#footer-top-menu {
    padding: 0;
    margin: 0;
  }
  
  footer#site-footer ul#footer-top-menu li {
    display: inline;
    list-style-type: none;
    margin-right: 1.32rem;
    font-weight: bold;
  }
  
  footer#site-footer ul#footer-top-menu li a {
    text-decoration: none;
  }
  
  footer#site-footer .footer-top-menu-item-active {
    position: relative;
  }
  
  footer#site-footer .footer-top-menu-item-active a {
    color: #007ba6;
  }
  
  footer#site-footer .footer-top-menu-item-active a:hover {
    color: #00c3ce;
  }
  
  footer#site-footer .footer-top-menu-item-active::before {
    content: "";
    background: #007ba6;
    padding: 0.15rem 0;
    width: 100%;
    position: absolute;
    top: 1.68em;
  }
  
  footer#site-footer .footer-top-menu-item-active:hover::before {
    background: #00c3ce;
    transition-duration: 0.6s;
  }
  
  footer#site-footer #footer-buttons-container {
    margin-left: auto;
    flex-shrink: 0;
  }
  
  footer#site-footer a.footer-button {
    background: transparent;
    border: 0.15rem solid #a8a8a8;
    border-radius: 1.38rem;
    padding: 0.5rem 1rem;
    font-weight: bold;
    text-decoration: none;
    display: inline-block;
  }
  
  footer#site-footer a.footer-button:hover {
    border-color: #00c3ce;
  }
  
  footer#site-footer section.horizontal-footer-section#footer-middle-section {
    align-items: flex-start;
  }
  
  footer#site-footer .footer-columns {
    flex-basis: 30%;
    padding-right: 1rem;
  }
  
  footer#site-footer .footer-base-columns {
    flex-basis: 40%;
  
  }
  
  footer#site-footer .footer-columns-large {
    flex-basis: 30%;
    padding-right: 2rem;
  }
  
  footer#site-footer .footer-columns h1 {
  
    margin-top: 0;
    margin-bottom: 1rem;
  
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
  }
  
  
  footer#site-footer .footer-columns.footer-columns-large h1 {
    font-size: 1.28rem;
    margin-top: 3rem;
    margin-bottom: 1.32rem;
  }
  
  footer#site-footer .footer-columns.footer-columns-large h1:first-child {
    margin-top: 0;
  }
  
  footer#site-footer ul.footer-column-menu {
    margin: 0;
    padding: 0;
  }
  
  footer#site-footer ul.footer-column-menu li {
    list-style-type: none;
    margin-bottom: 0.68rem;
    font-size: 0.88rem;
  }
  
  footer#site-footer #footer-about address {
    font-style: normal;
    font-weight: bold;
  }
  
  footer#site-footer #footer-about address p img {
    margin-right: 0.68rem;
  }
  
  footer#site-footer #footer-about a.footer-button {
    margin-top: 0.32rem;
  }
  
  footer#site-footer section.horizontal-footer-section#footer-bottom-section {
    padding: 1.32rem 0;
  
    border-top: 0.05rem solid #E1E1DF;
  }
  
  footer#site-footer section.horizontal-footer-section#footer-middle-section {
    padding: 1.32rem 0;
  
  
  }
  
  footer#site-footer #footer-social-buttons {
    justify-self: flex-end;
    margin-left: auto;
  }
  
  footer#site-footer #footer-social-buttons img {
    margin-left: 0.68rem;
  }
  
  footer#site-footer #footer-social-buttons img:hover {
    cursor: pointer;
  }
  
  .download-styling {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    margin-right: 15px;
    color: #333333;
  }
  
  .relative {
    position: relative;
  }
  
  .footer-middle-columns {
    display: flex;
    flex-basis: 50%;
  }
  .footer-middle-columns-end{
    justify-content: flex-end;
  }

  .footer-middle-columns a{
    margin: 0 15px !important;
  }

  .accordion-arrow{
    position: absolute;
        right: 50%;margin-top: -23px;cursor: pointer;
  }
  
  .rotate {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    transition: all 0.5s linear;
  
  }
  
  .rotate-down {
    transition: all .5s linear;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  

  .footeraddress {
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    flex-basis: 27%;
    height: auto !important;
  }
  .footer {
    background: #000000de;
    color: #fff;
    /* padding: 20px 30px 20px; */

  }
  .footerimg img {
    width: 60%;
    /* background: #fff; */
  }
  .footeraddress p, .footeraddress p a {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 0px;
    margin-top: 10px;
    color: #fff !important;
  }
  .copyright {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    width: 100%;
  }
  .socialicons {
    width: 100%;
    display: flex;
    border: none !important;
  }
  .linklist p {
    font-size: 14px;
    padding-right: 35px;
    text-align: justify;
  }
  .linklistul {
    flex-basis: 25%;
    height: auto !important;
    padding-left: 10%;
  }
  .linklistul ul {
    list-style-type: none;
    font-size: 13px;
    margin-bottom: 0;
  }
  .linklistul ul li {
    line-height: 2;
  }
  .linklistul ul li a{
    color: #fff !important;
  }
  .socialicons a svg {
    font-size: 22px;
    color: #000;
  }
  .footerlogowrp{
    /* margin: auto; */
  }
  .linklist ul li {
    line-height: 2;
  }

  .flexboxlink {
    display: flex;
    align-items: center;
    padding: 0 30px;
    border-top: 3px solid #fff;
  }
  .listlinkitemsbox {
    display: flex;
    max-width: 100% !important;
    padding: 20px 0;
  }
  .linklist {
    margin-top: 35px;
  }

  .footer .container-fluid{
    max-width: 1220px;
  }
  .whatsappchat:hover{
    color: #fff !important;
  }
  .whatsappchat svg{
    color: #fff !important;
    font-size: 20px !important;
  }
  .footerPhoneNo svg{ 
    color: #fff;
    font-size: 20px !important;
  }
  .footer a.nav-link , .whatsappchat{
    font-weight: 400!important;
    background: transparent;
    font-size: 13px;
    color: #fff !important;
  }

  .socialicons a{
    font-weight: 400!important;
    background: #fff;
    font-size: 13px;
    color: #fff !important;
    display: inline-flex;
    margin-right: 10px !important;
    padding: 10px !important;
    border-radius: 50%;
    margin-top: 15px !important;
  }
  
  .display-mobile {
  
    display: none;
  }
  
  @media only screen and (max-width: 858px) {
  
    .footer-desktop {
      display: none;
  
    }
  
    .display-mobile {
  
      display: block;
    }

    .footer-styling-downlaod {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      padding: 14px 0px;
      text-align: center;
    }
    .footer-middle-columns a {
      margin: 15px 7px !important;
    }
    .tandc-styling{
      padding: 0;
      padding-right: 10px;
    }
    .footerTermscondition-layout{
      justify-content: flex-start;
    }
    .copyRight-stying{
      text-align: left;
      margin: 15px 0;
    }
  
    .download-mob {
  
      width: 50%;
    }
  
    .mob-footer {
  
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: center;
  
  
    }
  
    .accordion-content-styling li {
      margin: 12px 0px;
      font-size: 16px;
    }
  
    .accordion {
      max-width: 100%;
  
      border-radius: 5px;
      overflow: hidden;
      font-family: "Quicksand", sans-serif;
      background: #ffff;
      padding: 0px 14px
    }
  
    .accordion__label,
    .accordion__content {
      padding: 14px 0px;
    }
  
    .accordion__label {
      display: block;
      color: #333333;
      font-weight: 500;
      cursor: pointer;
      position: relative;
      transition: background 0.1s;
      font-family: 'Poppins';
  
      border-bottom: 1px solid #ffff;
      box-shadow: 0px 1px 0px #e1e1df;
    }
  
  
    .accordion__label::after {
      content: "";
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      right: 0px;
      width: 16px;
      height: 11px;
      background-image: url(/static/media/arrow.2d652825.svg);
      background-size: contain;
      transition: -webkit-transform 0.4s;
      transition: transform 0.4s;
      transition: transform 0.4s, -webkit-transform 0.4s;
      background-repeat: no-repeat;
    }
  
    .accordion__content {
      background: #ffffff;
      line-height: 1.6;
      font-size: 0.85em;
      display: none;
    }
  
    .accordion__input {
      display: none;
    }
  
    .accordion__input:checked~.accordion__content {
      display: block;
    }
  
    .accordion__input:checked~.accordion__label::after {
      -webkit-transform: translateY(-50%) rotate(0.5turn);
              transform: translateY(-50%) rotate(0.5turn);
    }

    .linklistul{
      padding-left: 10px ;
    }
  }
  
.navbar-items {
    width: 60%;
}
.navbar{
    padding: 0px 0px !important;
}
nav ul.items-navbar {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    list-style-type: none;
}

.navbar-expand-lg .navbar-nav .nav-link {
    margin-left: 12px;
}

ul.items-navbar li a {
    font-size: 15px;
    color: #000;
    text-decoration: none;
    font-weight: 500;
}

ul.items-navbar li {
    margin: 0 7px;
    padding: 10px;
    text-align: center;
}

.user_section_notification {
    width: 100%;
    text-align: end;
    margin-top: 25px;
    position: relative;
}

.flight-ico {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.NavbarHeader{
    margin: 0 5px;
}

.logo {
    text-align: center;
    padding-top: 0px;
    display: flex;
    flex-basis: 10%;
    width: 25%;
}

.headerBox {
    background-color: #fff!important;
    border-bottom: 3px solid #fc9e15;
}

ul.items-navbar li a span svg {
    font-size: 20px;
}

svg.MuiSvgIcon-root {
    font-size: 20px;
}

button.btn-drop {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 15px;
}

button.btn-notify {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 15px;
}

.top-header-dropdown {
 
}
.balance-nav{
    width: 100%;
    display: flex;
}
.balanced-view {
    padding: 0px 10px;
    font-size: 13px;
    font-weight: 500;
    width: auto;
    color: #fc9e15;
    cursor: pointer;
    position: relative;
    border-bottom: 1px solid #ccc;
}

.dropdown-profile {
    text-align: center;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    position: relative;
}

.notify-cnt {
    width: 100%;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 6px rgb(0 0 0 / 30%);
}

.notify_show {
    display: block;
}

.notify_hide {
    display: none;
}

.notify-items {
    width: 100%;
    text-align: left;
    border-bottom: 1px solid #ccc;
    padding: 7px 10px;
    cursor: pointer;
}

.notify-items:hover {
    background: beige;
}

.notifycontenthover {
    position: absolute;
    width: 300px;
    right: 0;
    z-index: 9;
}

.higlighttxt {
    font-size: 13px;
    padding: 0 5px;
    white-space: nowrap;
    width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Blurtxt {
    font-size: 11px;
    padding: 0 5px;
}

.notify-viewmore {
    padding: 15px;
    text-align: center;
}

.viewmore {
    font-size: 15px;
    color: #1675b8;
}

.blc-list {
    background: #fff;
    padding: 5px;
    box-shadow: 0px 2px 3px rgb(0 0 0 / 30%);
    right: 0;
    top: 34px;
    border-radius: 8px;
}

.itemblc {
    display: flex;
    align-items: center;
    padding: 7px 10px;
}

.blnc-items-l {
    flex-basis: 50%;
    font-size: 14px;
    color: #000;
    font-weight: 500;
    text-align: start;
}

.blnc-items-r {
    flex-basis: 50%;
    text-align: end;
    color: #000;
    font-size: 14px;
    font-weight: 500;
}

.blnc-items-r.balance-price {
    color: red;
}

.balance_show {
    display: block;
}

.balance_hide {
    display: none;
}

.profile_view {
    display: flex;
}

.tltprofile {
    flex-basis: 100%;
}

button.profile {
    font-size: 12px;
    padding: 4px 10px;
    text-align: left;
    width: -webkit-max-content;
    width: max-content;
    
}

.viewdropdown {
    width: 270px!important;
    right: 1!important;
    top: 7px!important;
    left: -28px!important;
    z-index: 99;
}

.profile-bg {
    background: #ff6200;
    padding: 10px;
}

.tlt-img-ico {
    width: 70px;
    height: 70px;
    border: 6px solid #9fcc3a;
    border-radius: 50%;
    text-align: center;
    align-items: center;
    margin: 0 auto;
}

.tlt-img-ico img {
    width: 100%;
    object-fit: cover;
    text-align: center;
    border-radius: 50%;
    height: 100%;
}

.tlt-name {
    font-size: 17px;
    margin-top: 5px;
    color: #fff;
    font-weight: 500;
    text-align: right;
}

.activedate {
    font-size: 13px;
    color: #fff;
    padding-bottom: 10px;
}

.btnadmin {
    width: 100%;
    text-align: left;
    font-size: 14px;
}

.link-contact {
    display: flex;
    text-align: center;
}

.contactnumber {
    text-align: right;
    font-size: 13px;
}

.contactmail {
    text-align: right;
    font-size: 13px;
    text-transform: lowercase;
}

button.btnadminlink {
    font-size: 10px!important;
    padding: 4px 8px!important;
}

.link-contact {
    display: flex;
    text-align: center;
    align-items: center;
    padding: 4px 10px;
    background: azure;
}
.profilenChangespassword a {
    display: block;
    text-align: left;
}
ul.setlogout {
    padding: 0;
}
button.btnprofileset {
    padding: 5px 20px;
    font-size: 14px;
    text-transform: capitalize;
    width: 100%;
}
button.btnprofileset span {
    justify-content: flex-start;
}
button.changebtn {
    padding: 15px 20px !important;
    font-size: 15px;
    text-transform: capitalize;
    width: 100%;
    border-bottom: 1px solid #ccc;
    display: block !important;
    text-align: left;
    color: #000;
    font-weight: 500;
}
button.changebtn span {
    justify-content: flex-start;
}
button.linklogout {
    padding: 15px 20px !important;
    font-size: 15px;
    text-transform: capitalize;
    width: 100%;
    border-bottom: 1px solid #ccc;
    display: block !important;
    text-align: left;
    color: #000;
    font-weight: 500;
}
button.linklogout span {
    justify-content: flex-start;
}
ul a li.linklogout {
    font-size: 15px;
    color: #000;
    font-weight: 500;
    text-align: center!important;
    width: 86px;
    margin: 0 auto;
}

/***updated header css**/

.header {
    display: flex;
    justify-content: space-around;
    background: #ffff;
    height: 80px;
    -webkit-filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.16));
            filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.16));
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-items: center;
    z-index: 999;
}
.Main-content-header {
    z-index: 999;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}
.headercol {
    padding: 0!important;
}
.navbar-light .navbar-brand{
    flex-basis: 20%;
}

.navbar-light .navbar-nav .nav-link {
    color: #000!important;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
}

.navbar-light .navbar-nav .nav-link.active {
    color: #1675b8!important;
    font-weight: 500;
}

a.navbaritems.nav-link {
    padding: 0 12px!important;
}

.dropdown-toggle::after {
    display: none!important;
}

.dropdownitem.nav-item.dropdown a {
    margin-left: 5px;
    padding: 0 5px!important;
}
.dropdownitem.nav-item.dropdown a li {
    font-size: 15px;
}

a.recharge{
    margin-right: 10px !important;
    padding: 5px !important;
    font-size: 14px;
    font-weight: 500;
}

/******updated header css******/
.rc-menu{
    z-index: 999;
}
button.rc-menu-button.upadtedmenuitems {
    outline: none;
    border: none;
    background: transparent;
    font-weight: 500;
    margin: 0 6px;
    font-size: 18px;
    display: flex;
    color: #000;
}

button.rc-menu-button.upadtedmenuitems svg.MuiSvgIcon-root {
    font-size: 20px;
    margin-right: 5px;
    margin-top: 2px;
}

a.nav-link {
    font-weight: 400!important; 
    background: transparent;
    font-size: 13px;
}

.tlt-id{
    font-size: 13px;
    color: #fff;
    text-align: right;
}
.btnprofileset{
    color: #fff;
    text-align: right;
    margin-bottom: 0px;
    font-size: 11px;
    border: 1px solid;
    width: -webkit-max-content;
    width: max-content;
    float: right;
    padding: 2px 5px;
    margin-top: 5px;
}

.badge-custom-flight .MuiBadge-badge {
    font-size: 10px;
    height: 15px;
    background: #1675b8;
}

.topheader-left{
    flex-basis: 40%;
    justify-content: flex-start;
}
.topheader-center{
    flex-basis: 20%;
    justify-content: center;
    text-align: center;
}
.topheader-right{
    flex-basis: 40%;
    justify-content: flex-end;
}
.header .socialicons{
    text-align: center;
    justify-content: center;
    align-items: center !important;
    margin-right: 20px;
}
.header .socialicons a {
    font-weight: 400!important;
    background: #5c10da;
    font-size: 13px;
    color:  #ff6200!important;
    display: inline-flex;
    margin-right: 10px !important;
    height: 40px;
    width: 40px;
    padding: 11px !important;
    border-radius: 50%;
    margin-top: 0 !important;
   
}
* {
    padding: 0;
    margin: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
}

.header .socialicons a svg{
    font-size: 18px;
    color:  #fff!important;   
}

.nav-links {
    display: flex;
    align-self: center;
    align-items: center;
    float: right;
    margin-bottom: 0px;
}
.nav-links li {
    margin: 0 5px;
}
.nav-links li a, .nav-links li a:hover {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #000 !important;
    padding: 7px 10px !important;
}

.auth-btns .login-btn{
    width: -webkit-max-content;
    width: max-content;
    background: #5c10da !important;
    border: 1px solid #5c10da;
    color: #fff !important;
    font-weight: 400 !important;
    border-radius: 30px !important;
    padding: 6px 30px !important;
    font-size: 14px;
}

.auth-btns .signup-btn{
    width: -webkit-max-content;
    width: max-content;
    background: #fff !important;
    border: 1px solid #fff;
    color: #ff6200 !important;
    font-weight: 400 !important;
    border-radius: 30px !important;
    padding: 6px 30px !important;
    font-size: 14px;
}

.dailogbox-container .MuiDialog-paper{
    max-width: 900px;
    overflow: hidden;
  }
  .login-dialog .MuiDialog-paper{
    max-width: 750px;
    overflow: hidden;
  }
  
  .dailogbox {
    display: flex;
    max-width: 100%;
    height: 600px;
}

.login-dialog .modal-dialog{
    max-width: 800px;
}

.dailogbox-container .modal-dialog{
    max-width: 800px;
}
  .inputsect_login {
    width: 50%;
    padding: 22px;
    position: relative;
    text-align: center;
  }
  .inputsect_signup {
    width: 100%;
    padding: 22px;
    position: relative;
    text-align: center;
  }
  .slidersect {
    width: 50%;
    height: 480px;
    background-color: #0977bc;
  }
    
    .titlefrm {
      font-size: 20px;
  }
  .btnpostion {
    position: absolute;
    width: 100%;
    left: 0;
    padding: 15px;
  }
  .Btnlogin {
    width: 100%;
  }
  .loginfrg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
  }
  .loginfrg span {
    font-size: 14px;
    color: #000;
    font-weight: 400;
  }
  .textBoxlogin {
    margin-top: 6%;
  }
  .textBoxsign{
    margin-top: 5%;
  }
  img.logoimg {
    width: 100%;
    height: 600px;
    object-fit: cover;
  }

  .signup-modal img.logoimg {
    width: 100%;
    height: 650px;
    object-fit: cover;
  }
  .namewithtilebox {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
  }
  .titleuser {
    flex-basis: 18%;
  }
  .titlefirstname {
    flex-basis: 38%;
  }
  .titlelastname {
    flex-basis: 38%;
  }
  .titlecode {
    flex-basis: 20%;
  }
  .titlenumber {
    flex-basis: 77%;
  }
  .ttext {
    font-size: 13px;
    margin-top: 10px;
    color: #808080;
  }
  .btnpostion_signup {
    width: 100%;
    margin-top: 10px;
  }
  .img_with {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
  .logingoogle {
    padding: 10px 0;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .logingoogle iframe{
    border: none;
   margin: auto !important;
  }

   iframe #container{
    padding: 0px !important;
    width: 95% !important;
    margin-left: 16px;
  }
  
  .titleuser .MuiFormControl-root, .titlecode .MuiFormControl-root {
    width: 100%;
    margin-top: 8px
  }
  
  .titleuser .MuiSelect-select , .titlecode .MuiSelect-select{
    padding: 9px 8px !important;
  }
  
  .inputsect_signup label, .inputsect_login label{
    display: block;
    text-align: left;
    line-height: 10px;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 0px;
  }
.texttitle{
    position: relative;
    bottom: 0px !important;
    font-size: 12px;
    padding-bottom: 0px;
  }

  .loginlogo {
    width: 70px;
}

.emailfield input{
    padding: 10px;
    font-size: 14px;
}

.forgot-password span{
    font-size: 15px;
    color: #f44336 !important;
    margin-bottom: 5px !important;
    display: block;
    cursor: pointer;
}

.signup-text{
    margin-top: 10px;
}

.signup-text a{
    font-size: 13px;
}
.signup-text button{
    background: #fff;
    color: #ff6200;
    font-size: 13px;
}
.captchaSection {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
}
.captchaImg {
    height: 20px;
    width: 20px;
    margin : 5px;
}

.popupHeader h6 {
    font-size: 17px;
    font-weight: 600;
}

h3.subtlt-popup {
    font-size: 15px;
    font-weight: 500;
    padding: 0 9px;
    margin: 15px 0 0;
}
.inputforgot input {
    width: 100%;
    padding: 12px;
    font-size: 13px;
}
.inputforgot {
    width: 95%;
    margin: 8px 0 8px 10px!important;
}

.smalltxt {
    padding: 8px 12px;
    font-size: 14px!important;
    color: #a5a5a5;
}
.box-header-clickaway{
    width: 100%;
}
.logo-side {
    display: inline-block;
    position: absolute;
    top: 5px;
    margin-left: 5px;
}
.logo-side img {
    width: 100px;
    border-left: 1px solid #0003;
    padding-left: 4px;
    margin-top: 0px;
}
.logo-side p {
    margin-bottom: -2px;
    font-size: 8px;
    color: #000;
    margin-left: 10px;
}
@media (max-width: 786px) {
    .header .socialicons{
        justify-content:flex-start !important;
    }
    .navbar-light .navbar-brand {
        flex-basis: 100%;
    }
    .navbar-toggler-icon {
        width: 25px !important;
        height: 25px !important;
    }

    .Main-content-header {
        position: fixed;
        top: 0;
        width: 100%;
    }

    .navbar-collapse.collapse.show{
        position: absolute;
        background: #fff;
        width: 100%;
        top: 75px;
        right: 0px;
        box-shadow: 2px 4px 7px 2px #ccc;
        padding: 20px;
        text-align: center;
        z-index: 999;
    }

    .navbar-collapse.show{
        display: flex !important;
        align-items: flex-start !important;
        flex-flow: wrap-reverse;
    }
    .navbar-menu{
        margin-right: 15%;
        margin-left: 0px !important;
        text-align: left;
        align-items: baseline;
    }
    .tlt-name {
        font-size: 12px;
    }
    .top-header-dropdown {
        text-align: center;
        z-index: 9;
    }
    .itemblc {
        display: flex;
        align-items: center;
        padding: 4px 0px;
    }
    .navbar-expand{
        justify-content: flex-start !important;
        padding: 20px !important;
    }
    .navbar-expand .navbar-nav {
        flex-direction: column !important;
    }

    .header-topup {
        width: -webkit-max-content;
        width: max-content;
    }
    .box-header-clickaway{
        width: 20%;
        text-align: right;
    }
    .box-header-clickaway button{
        padding: 0px !important;
        border-radius: 2px !important;
        border: none;
    }
    .NavbarHeader {
        margin: 0 5px;
        flex-wrap: nowrap !important;
    }
    .header {
        padding: 0px !important;
        height: 55px;
        text-align: center;
    }
}
