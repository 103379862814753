 .footer{
    background: #fff;
 }
 
 .logo-padding {
    padding: 14px;
  }
  
  .mb-logo {
    display: none;
  }
  
  .nav-links {
  
    display: flex;
    align-self: center;
    align-items: center;
  }
  
  * {
    padding: 0;
    margin: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
  }
  
  body {
    font-family: Poppins;
  }
  
  footer nav {
  
    display: flex;
    justify-content: space-around;
    background: #ffff;
    height: 80px;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.16));
    position: sticky;
    top: 0;
    align-items: center;
    z-index: 99999;
  
  }
  
  label.logo {
    color: white;
    font-size: 35px;
    line-height: 80px;
    padding: 0 100px;
    font-weight: bold;
  }
  
  nav ul {
    float: right;
  }
  
  nav ul li {
  
    margin: 0 5px;
  }
  
  nav ul li a {
  
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
    padding: 7px 10px;
  
  }
  
  .icon-position {
    margin-top: 25px;
  }
  
  .checkbtn {
  
    display: none;
  }
  
  #check {
    display: none;
  }
  
  .desk-logo {
    display: block;
  }
  .footer-bottom{
    background-color: #000;
    padding: 5px;
  }
  .footer-bottom p{
    font-size: 15px;
  }
  .itwuxp-logo {
    width: 100px;
  }
  
  @media (max-width: 952px) {
    label.logo {
      font-size: 30px;
      padding-left: 50px;
    }
  
    nav ul li a {
      font-size: 16px;
    }
  
    nav {
      background: #ffff;
      height: 80px;
      width: 100%;
    }
  }
  
  @media (max-width: 858px) {
    .desk-logo {
      display: none;
  
    }
  
    .mb-logo {
  
      display: block;
    }
  
    nav {
      background: #ffff;
      height: 56px;
      width: 100%;
      justify-content: flex-start;
      padding-left: 16px;
  
    }
  
    .checkbtn {
      display: block;
    }
  
    ul {
      position: fixed;
      width: 100%;
      height: 100vh;
      background: #ffffff;
      top: 56px;
      left: -100%;
      text-align: center;
      transition: all .5s;
      display: block !important;
    }
  
    nav ul li {
      display: block;
      margin: 22px 0;
      line-height: 30px;
    }
  
    nav ul li a {
      font-size: 20px;
    }
  
    #check:checked~ul {
      left: 0;
      display: block;
    }
  }
  
  
  
  footer#site-footer {
    margin: 0 auto;
    width: 1200px;
    background: #ffff;
    padding: 1rem;
    color: #a8a8a8;
    height: 260px;
  }
  
  /*footer#site-footer a {
      color: #a8a8a8;
  }*/
  
  .normal-links-styling {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 36px;
    color: #666666;
    text-decoration: none;
  }
  
  .direction-styling {
    color: #5C0FD9;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
  }
  
  
  .address-styling {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #666666;
    width: 77%;
  }
  
  footer#site-footer h1 {
    color: #333333;
        align-self: center;
  }
  
  .copyRight-stying {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #666666 !important;
    text-align: center;
    margin-bottom: 0;
  }
  
  .tandc-styling {
    margin-bottom: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #5C0FD9 !important;
    padding: 0px 12px;
  
  }
  .tandc-styling a{
    color: #5C0FD9 !important;
  
  }
  
  
  .footerTermscondition-layout {
    display: flex;
    justify-content: flex-end;
  }
  
  footer#site-footer section.horizontal-footer-section {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    /*  padding: 2.38rem 2rem;*/
  }
  
  footer#site-footer section.horizontal-footer-section#footer-top-section {
    justify-content: center;
  }
  
  footer#site-footer #footer-logo {
    margin-right: auto;
    flex-shrink: 0;
  }
  
  footer#site-footer #footer-logo img {
    height: 2.28rem;
  }
  
  footer#site-footer ul#footer-top-menu {
    padding: 0;
    margin: 0;
  }
  
  footer#site-footer ul#footer-top-menu li {
    display: inline;
    list-style-type: none;
    margin-right: 1.32rem;
    font-weight: bold;
  }
  
  footer#site-footer ul#footer-top-menu li a {
    text-decoration: none;
  }
  
  footer#site-footer .footer-top-menu-item-active {
    position: relative;
  }
  
  footer#site-footer .footer-top-menu-item-active a {
    color: #007ba6;
  }
  
  footer#site-footer .footer-top-menu-item-active a:hover {
    color: #00c3ce;
  }
  
  footer#site-footer .footer-top-menu-item-active::before {
    content: "";
    background: #007ba6;
    padding: 0.15rem 0;
    width: 100%;
    position: absolute;
    top: 1.68em;
  }
  
  footer#site-footer .footer-top-menu-item-active:hover::before {
    background: #00c3ce;
    transition-duration: 0.6s;
  }
  
  footer#site-footer #footer-buttons-container {
    margin-left: auto;
    flex-shrink: 0;
  }
  
  footer#site-footer a.footer-button {
    background: transparent;
    border: 0.15rem solid #a8a8a8;
    border-radius: 1.38rem;
    padding: 0.5rem 1rem;
    font-weight: bold;
    text-decoration: none;
    display: inline-block;
  }
  
  footer#site-footer a.footer-button:hover {
    border-color: #00c3ce;
  }
  
  footer#site-footer section.horizontal-footer-section#footer-middle-section {
    align-items: flex-start;
  }
  
  footer#site-footer .footer-columns {
    flex-basis: 30%;
    padding-right: 1rem;
  }
  
  footer#site-footer .footer-base-columns {
    flex-basis: 40%;
  
  }
  
  footer#site-footer .footer-columns-large {
    flex-basis: 30%;
    padding-right: 2rem;
  }
  
  footer#site-footer .footer-columns h1 {
  
    margin-top: 0;
    margin-bottom: 1rem;
  
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
  }
  
  
  footer#site-footer .footer-columns.footer-columns-large h1 {
    font-size: 1.28rem;
    margin-top: 3rem;
    margin-bottom: 1.32rem;
  }
  
  footer#site-footer .footer-columns.footer-columns-large h1:first-child {
    margin-top: 0;
  }
  
  footer#site-footer ul.footer-column-menu {
    margin: 0;
    padding: 0;
  }
  
  footer#site-footer ul.footer-column-menu li {
    list-style-type: none;
    margin-bottom: 0.68rem;
    font-size: 0.88rem;
  }
  
  footer#site-footer #footer-about address {
    font-style: normal;
    font-weight: bold;
  }
  
  footer#site-footer #footer-about address p img {
    margin-right: 0.68rem;
  }
  
  footer#site-footer #footer-about a.footer-button {
    margin-top: 0.32rem;
  }
  
  footer#site-footer section.horizontal-footer-section#footer-bottom-section {
    padding: 1.32rem 0;
  
    border-top: 0.05rem solid #E1E1DF;
  }
  
  footer#site-footer section.horizontal-footer-section#footer-middle-section {
    padding: 1.32rem 0;
  
  
  }
  
  footer#site-footer #footer-social-buttons {
    justify-self: flex-end;
    margin-left: auto;
  }
  
  footer#site-footer #footer-social-buttons img {
    margin-left: 0.68rem;
  }
  
  footer#site-footer #footer-social-buttons img:hover {
    cursor: pointer;
  }
  
  .download-styling {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    margin-right: 15px;
    color: #333333;
  }
  
  .relative {
    position: relative;
  }
  
  .footer-middle-columns {
    display: flex;
    flex-basis: 50%;
  }
  .footer-middle-columns-end{
    justify-content: flex-end;
  }

  .footer-middle-columns a{
    margin: 0 15px !important;
  }

  .accordion-arrow{
    position: absolute;
        right: 50%;margin-top: -23px;cursor: pointer;
  }
  
  .rotate {
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    transition: all 0.5s linear;
  
  }
  
  .rotate-down {
    -moz-transition: all .5s linear;
    -webkit-transition: all .5s linear;
    transition: all .5s linear;
    transform: rotate(0deg);
  }
  

  .footeraddress {
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    flex-basis: 27%;
    height: auto !important;
  }
  .footer {
    background: #000000de;
    color: #fff;
    /* padding: 20px 30px 20px; */

  }
  .footerimg img {
    width: 60%;
    /* background: #fff; */
  }
  .footeraddress p, .footeraddress p a {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 0px;
    margin-top: 10px;
    color: #fff !important;
  }
  .copyright {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    width: 100%;
  }
  .socialicons {
    width: 100%;
    display: flex;
    border: none !important;
  }
  .linklist p {
    font-size: 14px;
    padding-right: 35px;
    text-align: justify;
  }
  .linklistul {
    flex-basis: 25%;
    height: auto !important;
    padding-left: 10%;
  }
  .linklistul ul {
    list-style-type: none;
    font-size: 13px;
    margin-bottom: 0;
  }
  .linklistul ul li {
    line-height: 2;
  }
  .linklistul ul li a{
    color: #fff !important;
  }
  .socialicons a svg {
    font-size: 22px;
    color: #000;
  }
  .footerlogowrp{
    /* margin: auto; */
  }
  .linklist ul li {
    line-height: 2;
  }

  .flexboxlink {
    display: flex;
    align-items: center;
    padding: 0 30px;
    border-top: 3px solid #fff;
  }
  .listlinkitemsbox {
    display: flex;
    max-width: 100% !important;
    padding: 20px 0;
  }
  .linklist {
    margin-top: 35px;
  }

  .footer .container-fluid{
    max-width: 1220px;
  }
  .whatsappchat:hover{
    color: #fff !important;
  }
  .whatsappchat svg{
    color: #fff !important;
    font-size: 20px !important;
  }
  .footerPhoneNo svg{ 
    color: #fff;
    font-size: 20px !important;
  }
  .footer a.nav-link , .whatsappchat{
    font-weight: 400!important;
    background: transparent;
    font-size: 13px;
    color: #fff !important;
  }

  .socialicons a{
    font-weight: 400!important;
    background: #fff;
    font-size: 13px;
    color: #fff !important;
    display: inline-flex;
    margin-right: 10px !important;
    padding: 10px !important;
    border-radius: 50%;
    margin-top: 15px !important;
  }
  
  .display-mobile {
  
    display: none;
  }
  
  @media only screen and (max-width: 858px) {
  
    .footer-desktop {
      display: none;
  
    }
  
    .display-mobile {
  
      display: block;
    }

    .footer-styling-downlaod {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      padding: 14px 0px;
      text-align: center;
    }
    .footer-middle-columns a {
      margin: 15px 7px !important;
    }
    .tandc-styling{
      padding: 0;
      padding-right: 10px;
    }
    .footerTermscondition-layout{
      justify-content: flex-start;
    }
    .copyRight-stying{
      text-align: left;
      margin: 15px 0;
    }
  
    .download-mob {
  
      width: 50%;
    }
  
    .mob-footer {
  
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: center;
  
  
    }
  
    .accordion-content-styling li {
      margin: 12px 0px;
      font-size: 16px;
    }
  
    .accordion {
      max-width: 100%;
  
      border-radius: 5px;
      overflow: hidden;
      font-family: "Quicksand", sans-serif;
      background: #ffff;
      padding: 0px 14px
    }
  
    .accordion__label,
    .accordion__content {
      padding: 14px 0px;
    }
  
    .accordion__label {
      display: block;
      color: #333333;
      font-weight: 500;
      cursor: pointer;
      position: relative;
      transition: background 0.1s;
      font-family: 'Poppins';
  
      border-bottom: 1px solid #ffff;
      box-shadow: 0px 1px 0px #e1e1df;
    }
  
  
    .accordion__label::after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0px;
      width: 16px;
      height: 11px;
      background-image: url('../../assets/images/icons/arrow.svg');
      background-size: contain;
      transition: transform 0.4s;
      background-repeat: no-repeat;
    }
  
    .accordion__content {
      background: #ffffff;
      line-height: 1.6;
      font-size: 0.85em;
      display: none;
    }
  
    .accordion__input {
      display: none;
    }
  
    .accordion__input:checked~.accordion__content {
      display: block;
    }
  
    .accordion__input:checked~.accordion__label::after {
      transform: translateY(-50%) rotate(0.5turn);
    }

    .linklistul{
      padding-left: 10px ;
    }
  }
  