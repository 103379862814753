body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #fff !important;
  overflow-x: hidden;
}
a {
  padding: 0!important;
  margin: 0!important;
  color: #000!important;
}
a:hover{
  text-decoration: none!important;
  color: #f6882d!important;
}

.calendarBox-airpassenger {
  padding: 15px;
  width: 92%;
  height: 60%;
  margin: 1% auto;
  border-radius: 10px;
}


.calendarBox {
  padding: 15px;
  width: 60%;
  height: 60%;
  margin: 1% auto;
  border-radius: 10px;
}
.fc .fc-daygrid-day-frame {
  position: relative;
  min-height: 103px;
  padding: 10px;
  font-weight: 500;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: aliceblue;
}
.daterate {
  width: 100%;
  font-size: 13px;
  color: #000;
}
.daterate label {
  margin: 0;
  width: 100%;
  display: flex;
  text-align: center;
  font-size: 14px;
  color: #4caf50;
  cursor: pointer;
}
.daterate span {
  margin: 0;
  width: 100%;
  display: block;
  text-align: left;
  font-size: 13px;
  color: #585454;
}
.fc-h-event {
  display: block;
  border: none;
  background-color: transparent;
}
table.fc-col-header tr {
  background: #ff6300;
}
a.fc-col-header-cell-cushion {
  font-size: 14px;
  font-weight: 500;
  color: #fff!important;
}
.fc .fc-toolbar-title {
  font-size: 20px;
  margin: 0;
  color: #ff6300;
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 8px;
}
.fc {
  width: 100%;
}
.fc .fc-button-primary {
  color: #fff;
  background: #ff6300;
}
table.fc-col-header tr th {
  border: 1px solid #ff6300;
}
.fc .fc-daygrid-day-top {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.tableico {
  margin-top: 5px;
  margin-bottom: 3px;
}

.tooltiprow {
  width: 100%;
}
.tooltipdtlsim{
  display: flex;
  align-items: flex-start;
}
.tooltipdtlsim label {
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  margin-top: 7px;
  padding-left: 10px;

}
.tooltipdtls label{
  width: 100%;
  font-size: 11px;
  font-weight: 500;
  margin: 0;
}
span.pricemini {
  font-size: 12px;
}
.fc-daygrid-dot-event:hover, .fc-daygrid-dot-event.fc-event-mirror {
  background: transparent;
}
.fc .fc-scrollgrid {
  border-collapse: separate;
  border-right-width: 0;
  border-bottom-width: 0;
}
.fc .fc-scroller-liquid-absolute {
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.fc .fc-scrollgrid-section-body table, .fc .fc-scrollgrid-section-footer table {
  border-bottom-style: hidden;
  background: #fff;
}